<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { DataTable } from "simple-datatables";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";

const props = defineProps({
  posts: {
    type: Array,
    default: () => []
  },
  postsSend: {
    type: Array,
    default: () => []
  },
  userId: {
    type: String,
    required: true
  },
  isOpen: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['close', 'view-post', 'delete-post']);
const error = ref(null);
let dataTable = null;

// Forbedret computed property til at håndtere forskellige datastrukturer
const allPosts = computed(() => {
  try {
    const combined = [
      // Håndter planlagte opslag (posts collection)
      ...props.posts.map(post => ({
        ...post,
        status: determinePostStatus(post),
        scheduledTime: post.publishDate && post.publishTime ? 
          new Date(`${post.publishDate} ${post.publishTime.HH}:${post.publishTime.MM}`) : 
          new Date(post.createdAt),
        type: 'scheduled',
        platforms: {
          facebook: post.postFB,
          instagram: post.postInst
        }
      })),
      
      // Håndter publicerede opslag (postsSend collection)
      ...props.postsSend.map(post => ({
        ...post,
        status: determinePostStatus(post),
        scheduledTime: new Date(post.publishedAt || post.createdAt),
        type: 'published',
        platforms: {
          facebook: post.postFB || post.postType === 'facebook',
          instagram: post.postInst || post.postType === 'instagram'
        }
      }))
    ];

    return combined
      .filter(post => post.scheduledTime && !isNaN(post.scheduledTime))
      .sort((a, b) => b.scheduledTime - a.scheduledTime);
      
  } catch (err) {
    error.value = 'Fejl ved behandling af opslag';
    console.error('Fejl i allPosts computed:', err);
    return [];
  }
});

// Tilføj ny hjælpefunktion til at bestemme status
const determinePostStatus = (post) => {
  try {
    // Hvis posten kommer fra posts collection (planlagte opslag)
    if (post.type === 'scheduled') {
      return 'pending';
    }

    // Hvis posten kommer fra postsSend collection
    if (post.type === 'published') {
      // Tjek for fejl først
      if (post.publishStatus === 'failed') {
        return 'failed';
      }
      
      // Hvis nogle af disse er sande, er opslaget publiceret
      if (post.publishedAt || 
          post.publishStatus === 'success' || 
          post.adCreativeStatus === 'success' ||
          hasAnalyticsData(post)) {
        return 'published';
      }
    }

    return 'pending';
  } catch (error) {
    console.error('Fejl i determinePostStatus:', error);
    return 'pending';
  }
};

// Hjælpefunktion til at tjekke analytics data
const hasAnalyticsData = (post) => {
  if (!post.analytics?.facebook) return false;
  const metrics = post.analytics.facebook;
  return metrics.post_impressions_unique > 0 || 
         metrics.post_engagements > 0 || 
         metrics.post_clicks > 0 || 
         metrics.post_reactions_like_total > 0;
};

// Forbedret formatering af tidspunkt
const formatScheduledTime = (post) => {
  try {
    if (!post || !post.scheduledTime) return 'Tidspunkt ikke angivet';
    
    const date = new Date(post.scheduledTime);
    if (isNaN(date)) return 'Ugyldig dato';

    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit',
      timeZone: 'Europe/Copenhagen'
    };
    
    return date.toLocaleDateString('da-DK', options);
  } catch (error) {
    console.error('Fejl ved formatering af dato:', error);
    return 'Fejl i datoformat';
  }
};

// Forbedret platform icons håndtering
const getPlatformIcons = (post) => {
  const icons = [];
  if (post.platforms?.facebook || post.postFB) icons.push('fab fa-facebook');
  if (post.platforms?.instagram || post.postInst) icons.push('fab fa-instagram');
  return icons;
};

// Opdateret status badge håndtering
const getStatusBadge = (post) => {
  const status = determinePostStatus(post);
  const statusMap = {
    pending: { color: 'warning', text: 'Planlagt' },
    published: { color: 'success', text: 'Publiceret' },
    failed: { color: 'danger', text: 'Fejlet' },
    processing: { color: 'info', text: 'Behandler' }
  };

  return statusMap[status] || { color: 'secondary', text: 'Ukendt' };
};

onMounted(() => {
  if (document.getElementById(`scheduled-posts-${props.userId}`)) {
    dataTable = new DataTable(`#scheduled-posts-${props.userId}`, {
      searchable: true,
      fixedHeight: false,
      perPage: 7,
      perPageSelect: false,
      labels: {
        placeholder: "Søg...",
        noRows: "Ingen opslag fundet",
        info: "Side {page} af {pages}",
        previous: "Forrige",
        next: "Næste"
      },
      pagination: {
        limit: 7,
        container: {
          classes: {
            paginationList: 'pagination',
            paginationListItem: 'page-item',
            paginationListItemLink: 'page-link'
          }
        }
      }
    });
  }
});

const closeModal = () => {
  if (dataTable) {
    dataTable.destroy();
    dataTable = null;
  }
  emit('close');
};

// Tilføj metode til at få preview tekst
const getPreviewText = (post) => {
  if (!post.message && !post.text) return 'Ingen beskedtekst';
  const text = post.message || post.text;
  return text.length > 100 ? `${text.substring(0, 100)}...` : text;
};

const isVideoUrl = (url) => {
  if (!url) return false;
  return url.toLowerCase().endsWith('.mp4') || 
         url.toLowerCase().includes('video') ||
         url.toLowerCase().includes('reel');
};
</script>

<template>
  <div v-if="isOpen" class="modal-overlay" @click.self="closeModal">
    <div class="modal-container">
      <div class="modal-header">
        <h3 class="modal-title">Sociale Medie Opslag</h3>
        <button class="close-button" @click="closeModal">
          <i class="fas fa-times"></i>
        </button>
      </div>
      
      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>

      <div v-else class="modal-body">
        <div class="table-responsive">
          <table :id="`scheduled-posts-${userId}`" class="table table-flush">
            <thead class="thead-light">
              <tr>
                <th>Billede</th>
                <th>Case ID</th>
                <th>Platform</th>
                <th>Tidspunkt</th>
                <th>Emne</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="post in allPosts" :key="post.id">
                <td class="align-middle">
                  <div class="media-preview" v-if="post.images?.[0]">
                    <video 
                      v-if="isVideoUrl(post.images[0])" 
                      :src="post.images[0]"
                      class="video-preview"
                      muted
                      loop
                      playsinline
                    ></video>
                    <img 
                      v-else 
                      :src="post.images[0]" 
                      alt="Post medie" 
                    />
                  </div>
                  <div v-else class="no-image">
                    <i class="fas fa-image"></i>
                  </div>
                </td>
                <td class="align-middle">
                  <div class="case-id">
                    {{ post.caseId }}
                  </div>
                </td>
                <td class="align-middle">
                  <div class="platform-icons">
                    <i 
                      v-for="icon in getPlatformIcons(post)" 
                      :key="icon" 
                      :class="icon"
                      class="platform-icon"
                    ></i>
                  </div>
                </td>
                <td class="align-middle">
                  <div class="scheduled-time">
                    <i class="far fa-calendar-alt me-2"></i>
                    <span>{{ formatScheduledTime(post) }}</span>
                  </div>
                </td>
                <td class="align-middle">
                  <span class="subject-badge">{{ post.subject || post.emne }}</span>
                </td>
                <td class="align-middle">
                  <argon-badge
                    :color="getStatusBadge(post).color"
                    class="badge-sm"
                  >
                    {{ getStatusBadge(post).text }}
                  </argon-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-container {
  background: rgba(32, 41, 64, 0.95);
  border-radius: 15px;
  width: 95%;
  max-width: 1400px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
}

.media-preview {
  width: 80px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.05);
  position: relative;
}

.media-preview img,
.media-preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-preview {
  background: #000;
}

.no-image {
  width: 80px;
  height: 60px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
}

.platform-icons {
  display: flex;
  gap: 10px;
}

.platform-icon {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
}

.scheduled-time {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
}

.subject-badge {
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.75rem;
}

.message-preview {
  max-width: 300px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
}

.status-indicator {
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.75rem;
  text-transform: capitalize;
}

.status-indicator.pending {
  background: rgba(255, 193, 7, 0.2);
  color: #ffc107;
}

.status-indicator.completed {
  background: rgba(40, 167, 69, 0.2);
  color: #28a745;
}

.status-indicator.failed {
  background: rgba(220, 53, 69, 0.2);
  color: #dc3545;
}

/* Tilføj eksisterende modal styling her... */

.date-group {
  margin-bottom: 2rem;
}

.date-header {
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.case-id {
  font-family: monospace;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
}

/* Opdater modal container til at være lidt større */
.modal-container {
  width: 98%;
  max-width: 1600px;
}

/* Tilføj hover effekt på rækker */
.table tbody tr:hover {
  background: rgba(255, 255, 255, 0.05);
  transition: background-color 0.2s ease;
}

/* Tilføj pagination styling */
:deep(.pagination) {
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center;
  margin-top: 1rem;
}

:deep(.page-item) {
  margin: 0 2px;
}

:deep(.page-link) {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.15s ease;
}

:deep(.page-link:hover) {
  background-color: rgba(255, 255, 255, 0.2);
}

:deep(.page-item.active .page-link) {
  z-index: 3;
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

:deep(.page-item.disabled .page-link) {
  color: rgba(255, 255, 255, 0.4);
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.05);
}

.badge-sm {
  font-size: 0.75rem;
  padding: 0.45em 0.775em;
  text-transform: none;
}
</style>
