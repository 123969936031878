<template>
  <div class="post-cars-component card-body border-radius-lg p-3" :class="{ 'invisible-card': isInvisible }">
    <!-- Step 1: Status valg -->
    <div v-if="currentStep === 1" class="content-container">
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="gradient-text me-3">Automatisér:</div>
        </div>
        <div class="col">
          <div class="form-container">
            <h6 class="mb-3">Status</h6>
            <div class="status-options">
              <div v-for="status in statusOptions" :key="status" class="status-option">
                <div class="status-toggle">
                  <label class="modern-switch">
                    <input type="checkbox" v-model="selectedStatus" :value="status">
                    <span class="modern-slider">
                      <span class="modern-dot"></span>
                    </span>
                  </label>
                  <span class="modern-label">{{ status }}</span>
                </div>
                <div v-if="status === 'Dage til salg' && selectedStatus.includes('Dage til salg')" 
                     class="days-input-modern">
                  <div class="interval-selection">
                    <label class="modern-switch">
                      <input type="checkbox" v-model="useAutoInterval">
                      <span class="modern-slider">
                        <span class="modern-dot"></span>
                      </span>
                    </label>
                    <span class="modern-label">Automatisk</span>
                  </div>
                  <input 
                    v-if="!useAutoInterval"
                    type="text" 
                    v-model="daysToSaleRange" 
                    class="modern-input" 
                    placeholder="Angiv antal dage mellem visninger"
                  >
                  <div v-else class="auto-interval-info">
                    Intervallet beregnes automatisk baseret på antal biler
                  </div>
                </div>
              </div>
            </div>
            <button @click="handleNext" type="button" class="btn bg-gradient-success btn-md mt-3">Næste</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 2: Kanal og type valg -->
    <div v-else-if="currentStep === 2" class="content-container">
      <div class="d-flex justify-content-between align-items-center w-100 mb-4">
        <button @click="handleBack" class="btn btn-outline-secondary btn-md">
          <i class="fas fa-arrow-left mr-2"></i> Tilbage
        </button>
        <div class="gradient-text mb-0">Vælg kanal og type</div>
        <button @click="handleNext" class="btn btn-outline-secondary btn-md">
          Næste <i class="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
      
      <div class="channel-type-container">
        <div class="channel-section">
          <h6 class="section-title">Kanal</h6>
          <div class="options-row">
            <div v-for="channel in channels" :key="channel.id" 
                 @click="toggleSelection(channel)"
                 class="option-item channel-option" 
                 :class="{ 'selected': channel.selected }">
              <div class="avatar">
                <img :src="channel.image" alt="channel image">
              </div>
            </div>
          </div>
        </div>
        
        <div class="type-section">
          <h6 class="section-title">Type</h6>
          <div class="options-row">
            <div v-for="type in types" :key="type.id" 
                 @click="toggleSelection(type)"
                 class="option-item media-type-option" 
                 :class="{ 'selected': type.selected }">
              <div class="avatar">
                <img :src="type.image" alt="type image">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 3: Design valg -->
    <div v-else-if="currentStep === 3" class="content-container">
      <GetDesign
        :design-types="designTypes"
        :design-images="designImages"
        :selected-design="selectedDesign"
        :selected-types="selectedTypes"
        :selected-channels="selectedChannels"
        v-model:selected-design="selectedDesign"
        @back="handleBack"
        @next="handleNext"
      />
    </div>
  </div>

  <!-- Tilføj Notifications component i bunden af template -->
  <Notifications 
    :message="notificationMessage" 
    :show="showNotification"
    :title="notificationTitle"
    :color="notificationColor"
    @update:show="showNotification = $event"
  />
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { getFirestore, collection, getDocs, doc, setDoc, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { initializeApp, getApps } from 'firebase/app';
import { generateImage } from '@/utils/imageGenerator'; // Tilføj denne import
import GetDesign from './getDesign.vue';
import Notifications from './Notifications.vue';
import { useUserContext } from '@/composables/useUserContext'; // Tilføj denne import

// Hent contextUserId fra useUserContext
const { contextUserId } = useUserContext();

// Firebase konfiguration
const firebaseConfig = {
  // Din Firebase konfiguration her
};

// Tjek om appen allerede er initialiseret
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0]; // Brug den eksisterende app
}

const db = getFirestore(app);

const statusOptions = ref([
  'Ny bil på lager', 'Prisjustering', 'Dage til salg',
  'Netop solgt'
]);

const selectedStatus = ref([]);
const currentStep = ref(1);
const channels = ref([
  { id: 'facebook', image: 'https://firebasestorage.googleapis.com/v0/b/estate-f5e0f.appspot.com/o/designs%2Ffilters%2Fnew%2Ffacebook.png?alt=media', selected: false },
  { id: 'instagram', image: 'https://firebasestorage.googleapis.com/v0/b/estate-f5e0f.appspot.com/o/designs%2Ffilters%2Fnew%2Finstagram.png?alt=media', selected: false }
]);
const types = ref([
  { id: 'image', image: 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/car-image.png?alt=media&token=d365475f-1ad6-49d1-8ef3-97ebe4de5997', selected: false },
  { id: 'video', image: 'https://firebasestorage.googleapis.com/v0/b/toft-d4f39.appspot.com/o/car_video.png?alt=media&token=558137e2-2dd6-42c0-9b62-70eadd0a72a2', selected: false }
]);

const designImages = ref([]);
const designTypes = ref([]);
const activeDesignType = ref('');

const selectedFormat = ref('');

const selectedDesign = ref([]);
const publishNow = ref(false);
const publishLater = ref(false);
const scheduledDateTime = ref('');

const togglePublishNow = () => {
  publishNow.value = !publishNow.value;
  if (publishNow.value) {
    publishLater.value = false;
    scheduledDateTime.value = '';
  }
};

const togglePublishLater = () => {
  publishLater.value = !publishLater.value;
  if (publishLater.value) {
    publishNow.value = false;
  }
};

const isFormValid = computed(() => {
  const hasDaysToSale = selectedStatus.value.includes('Dage til salg');
  const isValidDaysInput = !hasDaysToSale || 
                          (hasDaysToSale && useAutoInterval.value) || 
                          (hasDaysToSale && !useAutoInterval.value && daysToSaleRange.value.trim() !== '');
  const hasChannelAndType = channels.value.some(channel => channel.selected) && 
                           types.value.some(type => type.selected);

  return selectedStatus.value.length > 0 && 
         isValidDaysInput && 
         hasChannelAndType;
});

const fetchDesignImages = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'designImages'));
    designImages.value = querySnapshot.docs.map(doc => doc.data());
    console.log('Design Images:', designImages.value);
    
    // Udtræk unikke designtyper fra 'groups' feltet i de hentede designs
    const types = new Set(designImages.value.flatMap(design => design.groups || []).filter(Boolean));
    designTypes.value = Array.from(types);
    
    // Sæt den aktive designtype til den første i listen, hvis der er nogen
    if (designTypes.value.length > 0) {
      activeDesignType.value = designTypes.value[0];
    }
  } catch (error) {
    console.error('Fejl ved hentning af design billeder:', error);
  }
};

const logSelections = () => {
  console.log(`Status: ${selectedStatus.value.join(', ')} (choices - step 1)`);
  const selectedChannels = channels.value.filter(channel => channel.selected).map(channel => channel.id).join(', ');
  console.log(`Kanal: ${selectedChannels} (kanal - step 2)`);
  const selectedTypes = types.value.filter(type => type.selected).map(type => type.id).join(', ');
  console.log(`Type: ${selectedTypes} (type - step 2)`);
};

const statusToGroupMap = {
  'Nyhed': 'Ny bil',
  'Ny bil på lager': 'Ny bil',
  'Tilbud': 'Tilbud',
  'Prisjustering': 'Prisjustering',
  'Dagens bil': 'Dagens bil',
  'Ugens bil': 'Ugens bil',
  'Netop solgt': 'Netop solgt',
  'Eftertragtet model': 'Eftertragtet model',
  'Skarp pris': 'Skarp pris'
};

const filteredDesignImages = computed(() => {
  return designImages.value.filter(design => {
    if (!design?.file) return false;

    // Match status
    const matchesStatus = Array.isArray(design.emner) && 
      selectedStatus.value.some(status => 
        design.emner.some(emne => 
          emne.toLowerCase().includes(status.toLowerCase())
        )
      );

    // Match kanal
    const matchesChannel = Array.isArray(design.channels) && 
      (channels.value.filter(c => c.selected).length === 0 || // Vis alle hvis ingen er valgt
       channels.value.some(channel => 
         channel.selected && 
         design.channels.some(dc => 
           dc.toLowerCase() === channel.id.toLowerCase()
         )
       ));

    // Match type
    const isVideoFile = /\.mp4$/i.test(design.file.split('?')[0]);
    const isImageFile = /\.(jpg|jpeg|png)$/i.test(design.file.split('?')[0]);
    
    const matchesType = 
      types.value.filter(t => t.selected).length === 0 || // Vis alle hvis ingen er valgt
      (types.value.some(type => type.selected && type.id === 'video') && isVideoFile) ||
      (types.value.some(type => type.selected && type.id === 'image') && isImageFile);

    // Debug logging
    console.log(`Design ${design.uuid} filtering:`, {
      matchesStatus,
      matchesChannel,
      matchesType,
      selectedStatus: selectedStatus.value,
      designEmner: design.emner,
      channels: channels.value.filter(c => c.selected).map(c => c.id),
      designChannels: design.channels
    });

    return matchesStatus && matchesChannel && matchesType;
  });
});

const emit = defineEmits(['saved', 'cancelled', 'step-change']);

const handleNext = async () => {
  if (currentStep.value === 1) {
    if (selectedStatus.value.length === 0) {
      alert('Vælg venligst mindst én status før du fortsætter.');
      return;
    }
    if (selectedStatus.value.includes('Dage til salg') && 
        !useAutoInterval.value && 
        !daysToSaleRange.value.trim()) {
      alert('Angiv venligst et dagsinterval for "Dage til salg".');
      return;
    }

    try {
      // Brug contextUserId i stedet for auth.currentUser?.uid
      const userId = contextUserId.value;
      
      if (!userId) {
        throw new Error('Ingen bruger kontekst fundet');
      }

      const autoPostsRef = collection(db, 'users', userId, 'autoPosts');
      const q = query(autoPostsRef, where('status', '==', selectedStatus.value[0]));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        showToast(
          'Der findes allerede en automatisering med denne status. Slet eller rediger den eksisterende først.',
          'Advarsel',
          'warning'
        );
        return;
      }
    } catch (error) {
      console.error('Fejl ved tjek af eksisterende autoPost:', error);
      showToast(
        'Der skete en fejl ved validering af status. Prøv igen.',
        'Fejl',
        'danger'
      );
      return;
    }

    currentStep.value = 2;
    emit('step-change', 2);
  } 
  else if (currentStep.value === 2) {
    if (!channels.value.some(channel => channel.selected) || !types.value.some(type => type.selected)) {
      alert('Vælg venligst både en kanal og en type før du fortsætter.');
      return;
    }
    currentStep.value = 3;
    emit('step-change', 3);
  }
  else if (currentStep.value === 3) {
    if (selectedDesign.value.length === 0) {
      alert('Vælg venligst mindst ét design før du fortsætter.');
      return;
    }
    saveAutoPost();
  }
};

const handleBack = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
    emit('step-change', currentStep.value);
  }
};

const toggleSelection = (item) => {
  item.selected = !item.selected;
};

const isInvisible = ref(false); // Sæt denne til true for at gøre card'et usynligt

const prepareStepData = (step) => {
  switch (step) {
    case 1:
      // Forbered data til step 2
      console.log('Forbereder data til step 2');
      // Her kan du tilføje logik til at hente eller forberede data til step 2
      break;
    case 2:
      // Forbered data til step 3
      console.log('Forbereder data til step 3');
      // Her kan du tilføje logik til at hente eller forberede data til step 3
      break;
    default:
      break;
  }
};

const selectDesignType = (designType) => {
  activeDesignType.value = designType;
};

const selectDesign = (design) => {
  console.log('selectDesign kaldt med:', design);
  try {
    if (!design) {
      throw new Error('Intet design modtaget');
    }
    // Konverter til array hvis det ikke allerede er et
    selectedDesign.value = Array.isArray(design) ? design : [design];
    console.log('selectedDesign opdateret til:', selectedDesign.value);
    emit('update:selectedDesign', selectedDesign.value);
  } catch (error) {
    console.error('Fejl i selectDesign:', error);
    showToast('Fejl ved valg af design', 'Fejl', 'danger');
  }
};

const showPreview = ref(false);
const previewImageUrl = ref('');

const previewPost = async () => {
  if (!selectedDesign.value) {
    alert('Vælg venligst et design før forhåndsvisning.');
    return;
  }

  try {
    const imageUrl = await generateImage(selectedDesign.value.uuid);
    if (imageUrl) {
      previewImageUrl.value = imageUrl;
      showPreview.value = true;
    } else {
      alert('Der opstod en fejl ved generering af forhåndsvisning.');
    }
  } catch (error) {
    console.error('Fejl ved generering af forhåndsvisning:', error);
    alert('Der opstod en fejl ved generering af forhåndsvisning.');
  }
};

const closePreview = () => {
  showPreview.value = false;
};

const createPost = () => {
  if (!isFormValid.value) {
    alert('Venligst udfyld alle nødvendige felter.');
    return;
  }
  
  // Implementer oprettelse af opslaget her
  console.log('Opret opslag');
  console.log('Valgt status:', selectedStatus.value);
  console.log('Valgt design:', selectedDesign.value);
  console.log('Offentliggør nu:', publishNow.value);
  console.log('Planlæg opslag:', publishLater.value);
  if (publishLater.value) {
    console.log('Planlagt tidspunkt:', scheduledDateTime.value);
  }
};

// Kald prepareStepData, når komponenten monteres
onMounted(() => {
  fetchDesignImages();
  prepareStepData(currentStep.value);
});

// Overvåg currentStep og forbered data til det næste trin
watch(currentStep, (newStep) => {
  logSelections();
  prepareStepData(newStep);
  console.log('Current step changed to:', newStep);
  if (newStep === 3) {
    console.log('Step 3 data:', {
      designTypes: designTypes.value,
      filteredDesigns: filteredDesignImages.value,
      selectedStatus: selectedStatus.value
    });
  }
});

const selectedChannel = ref('');
const selectedMediaType = ref('');

const selectChannel = (channel) => {
  selectedChannel.value = channel;
};

const selectMediaType = (type) => {
  selectedMediaType.value = type;
};

const daysToSaleRange = ref('');
const useAutoInterval = ref(false); // Ny ref til at styre om vi bruger auto eller manuel

// Opdater watch for at logge ændringer
watch([selectedStatus, channels, types, activeDesignType], () => {
  console.log('Selection changed:', {
    status: selectedStatus.value,
    channels: channels.value.filter(c => c.selected).map(c => c.id),
    types: types.value.filter(t => t.selected).map(t => t.id),
    activeDesignType: activeDesignType.value
  });
});

// Tilføj en funktion til at logge når designs filtreres
const logDesignFiltering = (design) => {
  console.log(`Filtering design ${design.uuid}:`, {
    emner: design.emner,
    groups: design.groups,
    channels: design.channels,
    file: design.file
  });
};

// Opdater statusToCollectionMap så den matcher collections fra fetchAndStoreCars.js
const statusToCollectionMap = {
  'Ny bil på lager': 'newVehicles',
  'Prisjustering': 'newPriceVehicles', 
  'Netop solgt': 'soldVehicles',
  'Dage til salg': 'daysForSaleVehicles'
};

const notificationMessage = ref('');
const showNotification = ref(false);
const notificationTitle = ref('Notifikation');
const notificationColor = ref('success');

const showToast = (message, title = 'Notifikation', color = 'success') => {
  notificationMessage.value = message;
  notificationTitle.value = title;
  notificationColor.value = color;
  showNotification.value = true;
  setTimeout(() => {
    showNotification.value = false;
  }, 5000);
};

const saveAutoPost = async () => {
  try {
    const userId = contextUserId.value;
    
    if (!userId) {
      throw new Error('Ingen bruger kontekst fundet');
    }

    // Konverter selectedDesign til array hvis det er et enkelt objekt
    let designsToSave = selectedDesign.value;
    if (!Array.isArray(designsToSave)) {
      if (typeof designsToSave === 'object' && designsToSave !== null) {
        designsToSave = [designsToSave];
      } else {
        console.error('Ugyldigt design format:', designsToSave);
        throw new Error('Ugyldigt design format - kunne ikke konvertere til array');
      }
    }

    // Validér at vi har mindst ét design
    if (designsToSave.length === 0) {
      throw new Error('Ingen designs valgt');
    }

    const collectionName = statusToCollectionMap[selectedStatus.value[0]];
    if (!collectionName) {
      throw new Error('Ugyldig status - kunne ikke mappe til collection');
    }

    // Tjek om der allerede findes et aktivt autoPost for denne status
    const autoPostsRef = collection(db, 'users', userId, 'autoPosts');
    const q = query(autoPostsRef, 
      where('status', '==', selectedStatus.value[0]),
      where('active', '==', true)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      showToast(
        'Der findes allerede en aktiv automatisering med denne status. Deaktiver den eksisterende først.',
        'Advarsel',
        'warning'
      );
      return;
    }

    // Udtræk UUIDs fra designs
    const designUuids = designsToSave.map(design => {
      if (!design || !design.uuid) {
        console.error('Ugyldigt design objekt:', design);
        throw new Error('Design mangler UUID');
      }
      return design.uuid;
    });

    const selectedChannels = channels.value
      .filter(channel => channel.selected)
      .map(channel => channel.id);

    // Tjek om der er biler i collection (men fortsæt selvom der ikke er nogen)
    let carsCount = 0;
    try {
      const carsRef = collection(db, 'users', userId, collectionName);
      const carsSnapshot = await getDocs(carsRef);
      carsCount = carsSnapshot.size;
    } catch (error) {
      console.warn('Kunne ikke hente bil-count:', error);
      // Fortsæt uden bil-count
    }

    const autoPostData = {
      designUuids,
      status: selectedStatus.value[0],
      collectionName,
      channels: selectedChannels,
      createdAt: new Date(),
      active: true,
      userId: userId,
      carsCount: carsCount,
      lastUpdated: new Date(),
      settings: {
        allowEmptyCollection: true,
        autoActivate: true,
        useAutoInterval: useAutoInterval.value,
        manualInterval: useAutoInterval.value ? null : parseInt(daysToSaleRange.value)
      }
    };

    // Opret autoPost
    const newAutoPostRef = doc(autoPostsRef);
    await setDoc(newAutoPostRef, autoPostData);
    
    showToast(
      carsCount > 0 
        ? 'Automatisering er blevet oprettet med ' + carsCount + ' biler!'
        : 'Automatisering er blevet oprettet og vil aktiveres når der kommer biler.',
      'Succes',
      'success'
    );
    
    // Reset component state
    currentStep.value = 1;
    selectedStatus.value = [];
    channels.value.forEach(channel => channel.selected = false);
    types.value.forEach(type => type.selected = false);
    selectedDesign.value = [];

  } catch (error) {
    console.error('Fejl ved gemning af autoPost:', error);
    showToast(
      `Der skete en fejl ved oprettelse af automatisering: ${error.message}`,
      'Fejl',
      'danger'
    );
  }
};

// Computed properties for selected types og channels
const selectedTypes = computed(() => {
  return types.value
    .filter(type => type.selected)
    .map(type => type.id);
});

const selectedChannels = computed(() => {
  return channels.value
    .filter(channel => channel.selected)
    .map(channel => channel.id);
});
</script>

<style scoped>
.card-body {
  padding-bottom: 3rem !important;
  min-height: 500px;
  display: flex;
  align-items: center;
}

.invisible-card {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.invisible-card::before {
  content: none !important;
}

.invisible-card::after {
  content: none !important;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 300px; /* Juster denne værdi efter behov */
  justify-content: center;
  margin-top: -2rem;
}

.gradient-text {
  background-image: linear-gradient(0deg, hsla(0, 0%, 100%, .5), hsla(0, 0%, 100%, .5)), linear-gradient(90.1deg, #ad4f20 .08%, #ffffff 98.91%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: clamp(38px, 7vw, 46px); /* Øget skriftstørrelse her */
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.form-container {
  width: 100%;
  max-width: 400px;
}

.form-label {
  font-weight: bolder;
  font-size: 16px;
}

.select-button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.form-control {
  flex: 1 1 200px;
}

.btn {
  white-space: nowrap;
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    gap: 1rem;
  }

  .form-container {
    width: 100%;
    max-width: none;
  }
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.fas {
  font-family: "Font Awesome 5 Free";
}

.mr-2 {
  margin-right: 0.5rem;
}

.row.align-items-center {
  flex-wrap: nowrap;
}

.gradient-text {
  white-space: nowrap;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .row.align-items-center {
    flex-direction: column;
    align-items: flex-start !important;
  }
  
  .gradient-text {
    margin-bottom: 1rem;
  }
}

.hoveredImg {
  transition: all 0.3s ease;
}

.hoveredImg:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.hoveredImg.selected {
  box-shadow: 0 0 15px rgba(0, 123, 255, 0.5);
  border: 2px solid #007bff;
}

.mainCardClass {
  margin-bottom: 20px;
}

.card-body {
  padding: 1rem;
}

.form-check-input {
  width: 3rem;
  height: 1.5rem;
}

.btn-md {
  padding: 0.65rem 1.25rem;
}

.gradient-text {
  font-size: clamp(24px, 5vw, 38px);
}

.card {
  background-color: #303030;
  border-radius: 15px;
  margin-bottom: 20px;
}

.card-header {
  background-color: #303030;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.card-body {
  padding: 1rem;
}

.designGroup {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.designGroup.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-control {
  background-color: #424242;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 3.5rem !important;
}

.btn {
  margin: 0 5px;
}

.custom-switch .form-check-input {
  height: 1.5rem;
  width: 3rem;
}

.hoveredImg {
  transition: all 0.3s ease;
}

.hoveredImg:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
}

.mainCardClass {
  margin-bottom: 20px;
}

.card {
  border-radius: 15px;
  overflow: hidden;
}

.card-body {
  padding: 0 !important;
}

.card img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card .d-flex.justify-content-between {
  padding: 15px;
}

.card p {
  font-size: 14px;
  margin-bottom: 0;
}

.btn-md {
  font-size: 0.875rem;
  padding: 0.65rem 1.25rem;
}

.row_gap_5 {
  row-gap: 1.25rem;
}

.card-body img {
  object-fit: cover;
  border-radius: 8px;
}

.button {
  font-size: 0.65rem !important;
  line-height: 1.2 !important;
  padding: 0.15rem 0.3rem !important;
}

.designs-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}


.design-card.selected,
.option-container.selected {
  border: 2px solid #2dce89;
  box-shadow: 0 0 10px rgba(45, 206, 137, 0.5);
}

.option-container {
  display: flex;
  align-items: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-label {
  margin-bottom: 0;
  font-weight: 500;
}

.design-card {
  background-color: #2c2c2c;
  border-radius: 8px;
  overflow: hidden;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;/* 250px for billedet + 10px padding (5px på hver side) */
  height: auto; /* Lad højden tilpasse sig indholdet */
  margin: 0 auto; /* Centrerer kortet hvis det er i et grid */
}

.design-image {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.designGroup {
  cursor: pointer;
  transition: color 0.3s ease;
}

.designGroup:hover {
  color: #5e72e4;
}

.designGroup.active {
  color: #5e72e4;
  font-weight: bold;
}

.btn {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.btn-md {
  font-size: 0.875rem;
  padding: 0.65rem 1.25rem;
}

.gradient-text {
  font-size: clamp(24px, 5vw, 38px);
}

.card-header h4 {
  font-size: 1.25rem;
}

.form-control {
  font-size: 0.875rem;
}

/* Tilføj disse styles for at ensrette knapperne */
.btn-outline-secondary,
.bg-gradient-success,
.bg-gradient-dark {
  border-radius: 0.5rem;
  transition: all 0.15s ease-in-out;
}

.btn-outline-secondary:hover,
.bg-gradient-success:hover,
.bg-gradient-dark:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* Ensret mellemrum mellem knapper */
.btn + .btn {
  margin-left: 0.5rem;
}

/* Juster alignmentet af knapper i header */
.d-flex.justify-content-between {
  padding: 1rem 0;
}

/* Ensret styling af kort */
.card {
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.design-card.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 10px rgba(94, 114, 228, 0.5);
}

.custom-switch {
  width: 3rem;
  height: 1.5rem;
}

.form-container {
  width: 100%;
  max-width: 450px;
}

.gradient-text {
  font-size: clamp(32px, 5vw, 42px);
  background: linear-gradient(90deg, #eee, #777);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.option-container {
  display: flex;
  align-items: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}

.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #2dce89;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.custom-checkbox:checked {
  background-color: #2dce89;
}

.custom-checkbox:checked::after {
  content: '\2714';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  height: 30px;
}

.btn-md {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #7928CA 0%, #FF0080 100%);
}

.bg-gradient-success {
  background-image: linear-gradient(310deg, #2dce89 0%, #26c6da 100%);
}

.btn-outline-secondary {
  border-width: 2px;
}

.btn-outline-secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.d-flex.justify-content-between {
  margin-top: 2rem;
}

.design-card.selected,
.option-container.selected,
.channel-option.selected,
.media-type-option.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 10px rgba(94, 114, 228, 0.5);
}

/* Tilføj disse nye styles hvis de ikke allerede eksisterer */
.channel-option,
.media-type-option {
  border-radius: 1rem;
  height: 27.6rem; /* Øget fra 23rem (20% større) */
  width: 20.4rem; /* Øget fra 17rem (20% større) */
  max-width: 20.4rem; /* Øget fra 17rem (20% større) */
  margin: 0.5rem;
  padding: 0;
  background: #303030;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.channel-type-container .channel-option,
.channel-type-container .media-type-option {
  border-radius: 1rem !important;
  height: 27.6rem !important;
  width: 20.4rem !important;
  max-width: 20.4rem !important;
  margin: 0.5rem !important;
  padding: 0 !important;
  background: #303030 !important;
  cursor: pointer !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  transition: all 0.3s ease !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.channel-type-container .channel-option.selected,
.channel-type-container .media-type-option.selected {
  border: 2px solid #5e72e4 !important;
  box-shadow: 0 0 15px rgba(94, 114, 228, 0.7) !important;
}

.avatar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar img {
  height: 22.8rem; /* Øget fra 19rem (20% større) */
  width: 15.6rem; /* Øget fra 13rem (20% større) */
  object-fit: cover;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.option-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.option-container .form-label {
  margin-bottom: 0;
  font-weight: 500;
  color: white; /* Gør teksten hvid */
}

.option-container.selected {
  border: 2px solid #5e72e4;
  box-shadow: 0 0 15px rgba(94, 114, 228, 0.7);
}

.option-wrapper {
  margin-top: 5rem; /* Positiv værdi for at flytte indholdet ned */
}

.option-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 60px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-content {
  position: relative;
  width: 90vw;
  height: 90vh;
  max-width: 1200px;
  max-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 16px;
  background-color: rgba(244, 67, 54, 0.7);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.close-button:hover {
  background-color: rgba(244, 67, 54, 1);
}

@media (max-width: 768px) {
  .preview-content {
    width: 95vw;
    height: 95vh;
  }

  .close-button {
    padding: 6px 12px;
    font-size: 14px;
  }
}

.option-wrapper {
  display: flex;
  flex-direction: column;
}

.option-wrapper .d-flex {
  gap: 1rem;
}

.option-container {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 180px; /* Juster denne værdi efter behov */
  height: 60px; /* Fast højde for at sikre ensartethed */
}

.form-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 0.9rem; /* Juster skriftstørrelsen efter behov */
}

.channel-type-container {
  display: flex !important;
  justify-content: center !important;
  gap: 120px !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
}

.channel-type-container .channel-section,
.channel-type-container .type-section {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.channel-type-container .channel-option,
.channel-type-container .media-type-option {
  border-radius: 1rem !important;
  height: 20.6rem !important;
  width: 14.4rem !important;
  max-width: 20.4rem !important;
  margin: 0.5rem !important;
  padding: 0 !important;
  background: #303030 !important;
  cursor: pointer !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  transition: all 0.3s ease !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.channel-type-container .channel-option .avatar,
.channel-type-container .media-type-option .avatar {
  height: 19rem !important;
  width: 13rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.channel-type-container .channel-option .avatar img,
.channel-type-container .media-type-option .avatar img {
  max-height: 100% !important;
  max-width: 100% !important;
  object-fit: contain !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.options-row {
  display: flex;
  justify-content: center;
  gap: 20px; /* Mellemrum mellem muligheder i hver sektion */
  margin-top: 1rem;
}

.option-item {
  width: 170px; /* Juster dette efter behov */
  height: 230px; /* Juster dette efter behov */
}

.avatar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.section-title {
  text-align: center;
  margin-bottom: 1rem;
}

.d-flex.justify-content-between {
  padding: 0rem !important;
}

.status-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  padding: 1rem 0;
}

.status-option {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.status-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.modern-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;
}

.modern-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.modern-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(120, 120, 128, 0.16);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 13px;
}

.modern-dot {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.modern-switch input:checked + .modern-slider {
  background-color: #2dce89;
}

.modern-switch input:checked + .modern-slider .modern-dot {
  transform: translateX(18px);
}

.modern-label {
  font-size: 0.9375rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  user-select: none;
}

.days-input-modern {
  position: relative;
  width: 100%;
}

.modern-input {
  width: 100%;
  height: 36px;
  padding: 0 12px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.modern-input:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.15);
  border-color: #2dce89;
}

.modern-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Hover effects */
.modern-switch:hover .modern-slider {
  background-color: rgba(255, 255, 255, 0.2);
}

.modern-input:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

/* Opdateret slider baggrundsfarve for bedre kontrast */
.modern-slider {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Opdateret dot farve for bedre kontrast mod mørk baggrund */
.modern-dot {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
</style>