<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import { 
  collection, 
  onSnapshot, 
  query, 
  where, 
  deleteDoc, 
  addDoc, 
  getDocs, 
  orderBy,
  getFirestore,
  doc,
  getDoc
} from "firebase/firestore";
import { db, auth } from "@/firebase";
import { deleteUser } from "firebase/auth";
import CompanyAddComponent from "./CompanyAddComponent.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { updateDoc } from "firebase/firestore";
import { Timestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from "@/firebase";
import { usePasswordManagement } from '../components/usePasswordManagement';
import OrderList from "./OrderList.vue";
import { Teleport } from 'vue';

// Definer roller som konstanter
const ROLE_ADMIN = 1;
const ROLE_MANAGER = 2;
const ROLE_USER = 3;

const store = useStore();
const router = useRouter();

const showAddCompanyModal = ref(false);
const users = ref([]);
const showAllUserListButton = ref("false")
const searchQuery = ref("");
const isLoading = ref({});

const userAutoPosts = ref(new Set());

const unsubscribeMetaWatch = ref(null);

const cleanupMetaWatch = () => {
  if (unsubscribeMetaWatch.value) {
    unsubscribeMetaWatch.value();
    unsubscribeMetaWatch.value = null;
  }
};

const openAddCompanyModal = () => {
  showAddCompanyModal.value = true;
};

const closeAddCompanyModal = () => {
  showAddCompanyModal.value = false;
};

let unsubscribe;

onMounted(async () => {
  const usersCollection = collection(db, "users");
  const q = query(usersCollection, store.state.activeUser.role === 2 ? 
    where("createdBy", "==", store.state.activeUser.id) : null);

  unsubscribe = onSnapshot(q, async (querySnapshot) => {
    const usersArray = [];
    querySnapshot.forEach((doc) => {
      usersArray.push({ id: doc.id, ...doc.data() });
    });
    users.value = usersArray;
    isLoading.value = false;

    // Tjek autoposts for hver bruger efter users er opdateret
    for (const user of usersArray) {
      const autoPostsRef = collection(db, 'users', user.id, 'autoPosts');
      const autoPostsSnapshot = await getDocs(autoPostsRef);
      
      if (!autoPostsSnapshot.empty) {
        userAutoPosts.value.add(user.id);
      }

      // Opsæt realtime listener for hver bruger
      onSnapshot(autoPostsRef, (snapshot) => {
        if (!snapshot.empty) {
          userAutoPosts.value.add(user.id);
        } else {
          userAutoPosts.value.delete(user.id);
        }
      });
    }
  });
});

onUnmounted(() => {
  if (unsubscribe) unsubscribe();
  cleanupMetaWatch();
});

const filteredUsers = computed(() => {
  if (!users.value) return [];
  return users.value.filter(
    (user) =>
      user &&
      user.name &&
      user.email &&
      (user.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.value.toLowerCase())) &&
      [ROLE_MANAGER, ROLE_USER].includes(user.role) // Inkluder kun roller 2 og 3
  );
});

// Brug getters til at få den aktive bruger
const currentUser = computed(() => {
  const user = store.getters.activeUser;
  // Tjek om vi har et gyldigt user objekt med rolle
  if (!user) return null;
  
  // Hvis role findes direkte på objektet
  if (user.role) return user;
  
  // Hvis role findes i reloadUserInfo
  if (user.reloadUserInfo?.role) return { ...user, role: user.reloadUserInfo.role };
  
  // Hvis vi har uid/id, men mangler role, redirect til signin
  if (user.uid || user.id) {
    router.push('/authentication/signin');
    return null;
  }
  
  return null;
});

// Tilføj en watch på currentUser hvis nødvendigt
watch(currentUser, (newValue) => {
  console.log('currentUser ændret:', newValue)
  if (!newValue) {
    // Håndter situationen hvor bruger er null
    router.push('/authentication/signin')
  }
})

// Funktion til at få rolle navn
const getRoleName = (role) => {
  switch (role) {
    case ROLE_ADMIN:
      return "Admin";
    case ROLE_MANAGER:
      return "Manager";
    case ROLE_USER:
      return "Bruger";
    default:
      return "Ukendt";
  }
};

const accessUserProfile = (userId) => {
  if (!userId) return;
  
  const currentUser = store.state.activeUser;
  const isAdminOrSubAdmin = currentUser?.role === 1 || currentUser?.role === 2;

  if (isAdminOrSubAdmin) {
    router.push(`/profile/${userId}`);
  } else {
    router.push('/profile');
  }
};

const markUserRole = async(user, role) => {
  console.log(user, user.id)
  if (user && user.id) {
    try {
      const userDocRef = doc(db, "users", user.id);
      await updateDoc(userDocRef, {
        role: role
      });
      // alert("Profil opdateret!");

    } catch (error) {
      console.error("Fejl ved opdatering af profil: ", error);
      alert(`Fejl ved opdatering af profil: ${error.message}`);
    }
  } else {
    console.log("Ingen bruger er logget ind eller bruger-ID mangler.");
  }
}

const allUsers = async(user) => {
  showAllUserListButton.value = true
  const usersCollection = collection(db, "users");
  const q = query(usersCollection, where("createdBy", "==", user.id));
  unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const usersArray = [];
      querySnapshot.forEach((doc) => {
        usersArray.push({ id: doc.id, ...doc.data() });
      });
      console.log("Hentede brugere:", usersArray);
      users.value = usersArray;
      isLoading.value = false;
    },
    (error) => {
      console.error("Fejl ved hentning af brugere:", error);
      isLoading.value = false;
    },
  );
}

const allUserLists = async(user) => {
  showAllUserListButton.value = false
  const usersCollection = collection(db, "users");
  const q = query(usersCollection);
  unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const usersArray = [];
      querySnapshot.forEach((doc) => {
        usersArray.push({ id: doc.id, ...doc.data() });
      });
      console.log("Hentede brugere:", usersArray);
      users.value = usersArray;
      isLoading.value = false;
    },
    (error) => {
      console.error("Fejl ved hentning af brugere:", error);
      isLoading.value = false;
    },
  );
}

const deleteUserConfirmation = async (user) => {
  try {
    if (!confirm(`Er du sikker på, at du vil slette ${user.name}?`)) {
      return;
    }

    // Initialiser isLoading som et objekt hvis det ikke allerede er det
    if (!isLoading.value || typeof isLoading.value !== 'object') {
      isLoading.value = {};
    }
    
    // Sæt loading state for denne specifikke bruger
    isLoading.value[`delete-${user.id}`] = true;

    // Kald backend via HTTP for at slette bruger fra Authentication
    const response = await fetch('https://us-central1-toft-d4f39.cloudfunctions.net/deleteUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: user.id
      })
    });

    if (!response.ok) {
      throw new Error('Kunne ikke slette bruger fra Authentication');
    }

    // Slet bruger fra Firestore
    const userRef = doc(db, "users", user.id);
    await deleteDoc(userRef);

    // Fjern bruger fra den lokale liste
    users.value = users.value.filter(u => u.id !== user.id);

    console.log('Bruger slettet succesfuldt');
    alert('Bruger er blevet slettet');

  } catch (error) {
    console.error('Fejl ved sletning af bruger:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    // Fjern loading state for denne specifikke bruger
    if (isLoading.value && typeof isLoading.value === 'object') {
      isLoading.value[`delete-${user.id}`] = false;
    }
  }
};

const setupAutoPosts = async (user) => {
  try {
    const userDocRef = doc(db, "users", user.id);
    const userDocSnap = await getDoc(userDocRef);
    const userData = userDocSnap.data();
    
    if (!userData.dealerId) {
      alert('Bruger mangler dealer ID. Opdater venligst dette først.');
      return;
    }

    const defaultSetups = [
      {
        status: 'Ny bil på lager',
        daysToSale: null,
        useAutoInterval: false
      },
      {
        status: 'Prisjustering',
        daysToSale: null,
        useAutoInterval: false
      },
      {
        status: 'Dage til salg',
        daysToSale: '17',
        useAutoInterval: true
      },
      {
        status: 'Netop solgt',
        daysToSale: null,
        useAutoInterval: false
      }
    ];

    // Hent alle tilgængelige designs
    const designsSnapshot = await getDocs(collection(db, 'designImages'));
    const allDesigns = designsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    let createdCount = 0;
    const autoPostsRef = collection(db, 'users', user.id, 'autoPosts');

    for (const setup of defaultSetups) {
      // Find matchende designs for denne status
      const matchingDesigns = allDesigns.filter(design => 
        design.emner?.some(emne => 
          emne.toLowerCase().includes(setup.status.toLowerCase())
        )
      );

      if (matchingDesigns.length === 0) {
        console.warn(`Ingen designs fundet for status: ${setup.status}`);
        continue;
      }

      // Tjek om der allerede findes en autoPost med denne status
      const existingQuery = query(autoPostsRef, where('status', '==', setup.status));
      const existingDocs = await getDocs(existingQuery);
      
      if (!existingDocs.empty) {
        console.log(`AutoPost for ${setup.status} findes allerede - springer over`);
        continue;
      }

      const autoPostData = {
        status: setup.status,
        channels: ['facebook'],
        types: ['image'],
        designUuids: matchingDesigns.map(design => design.uuid),
        createdAt: new Date(),
        active: !!userData.MetaSettings?.facebookPageId,
        userId: user.id,
        settings: {
          allowEmptyCollection: true,
          autoActivate: true,
          daysToSale: setup.daysToSale,
          useAutoInterval: setup.useAutoInterval,
          manualInterval: setup.useAutoInterval ? null : setup.daysToSale,
          dealerId: userData.dealerId,
          facebookPageId: userData.MetaSettings?.facebookPageId || null
        },
        lastUpdated: new Date()
      };

      // Opret autoPost i Firestore
      await addDoc(autoPostsRef, autoPostData);
      createdCount++;
      console.log(`AutoPost oprettet for: ${setup.status}`);
    }

    // Vis success besked
    if (createdCount > 0) {
      alert(`Quick Setup gennemført! ${createdCount} autoposts er blevet oprettet.`);
    } else {
      alert('Ingen nye autoposts blev oprettet. Alle opsætninger findes allerede.');
    }
    
    // Efter vellykket oprettelse af AutoPosts, marker at setup er udført
    await updateDoc(userDocRef, {
      hasSetupAutoPosts: true,
      lastAutoPostSetup: new Date()
    });

    // Opdater lokalt user objekt
    user.hasSetupAutoPosts = true;
    
  } catch (error) {
    console.error('Fejl ved Quick Setup:', error);
    alert(`Fejl ved Quick Setup: ${error.message}`);
  }
};

// Hjælpefunktion til at tjekke om en bruger har AutoPost
const hasAutoPost = (userId) => {
  console.log('Checking autopost for user:', userId, 'Has autopost:', userAutoPosts.value.has(userId));
  return userAutoPosts.value.has(userId);
};

// Tilføj konstant for access token
const META_USER_ACCESS_TOKEN = 'EAAvJ5UcfHO0BAMufob4DZCtz7rG33RZBb98Ddiy1KMo0m5UPRmYsrJ6zr5XYid2mbY26OK7m9hcc9ngk2MaNckLN3jaOEAwnqIbhSv9HZCzopfrqm8TYQop93y4SEjZCPtLZCeg9W13NA83CRaQdBHCNAsDXBEbXnyiyyjtn7XAZDZD';

// Tilføj loading state
const metaSettingsLoading = ref(new Set());

const connectMetaSettings = async (user) => {
  try {
    // Tilføj bruger til loading state
    metaSettingsLoading.value.add(user.id);
    
    // Normaliser og rens brugernavnet
    const normalizeString = (s) => {
      const normalized = s.normalize('NFKD')
        .replace(/Å/g, 'Aa').replace(/å/g, 'aa')
        .replace(/Æ/g, 'Ae').replace(/æ/g, 'ae')
        .replace(/Ø/g, 'Oe').replace(/ø/g, 'oe');
      
      return normalized
        .replace(/[^\x00-\x7F]/g, '')
        .replace(/\s*-\s*/g, ' ')
        .toLowerCase()
        .trim();
    };

    const searchName = normalizeString(user.name);
    console.log('Søger efter:', searchName);
    
    // Hent Facebook sider
    const response = await fetch(
      `https://graph.facebook.com/v20.0/me/accounts?fields=name,id,instagram_business_account&limit=500&access_token=${META_USER_ACCESS_TOKEN}`
    );
    const data = await response.json();
    console.log('Facebook sider:', data);

    // Find matching Facebook side
    const matchingPage = data.data.find(page => {
      const fbName = normalizeString(page.name);
      return searchName.includes(fbName) || fbName.includes(searchName);
    });

    if (!matchingPage) {
      alert('Ingen matchende Facebook-side fundet');
      return;
    }

    console.log('Matchende side fundet:', matchingPage);

    // Hent page access token
    const tokenResponse = await fetch(
      `https://graph.facebook.com/${matchingPage.id}?fields=access_token&access_token=${META_USER_ACCESS_TOKEN}`
    );
    const tokenData = await tokenResponse.json();

    if (!tokenData.access_token) {
      throw new Error('Kunne ikke hente page access token');
    }

    console.log('Page access token hentet');

    // Hent yderligere side information
    const pageDetailsResponse = await fetch(
      `https://graph.facebook.com/${matchingPage.id}?fields=name,about,phone,location,website&access_token=${META_USER_ACCESS_TOKEN}`
    );
    const pageDetails = await pageDetailsResponse.json();
    console.log('Side detaljer:', pageDetails);

    // Opdater brugerens MetaSettings
    const userDocRef = doc(db, "users", user.id);
    const metaSettings = {
      name: pageDetails.name || matchingPage.name,
      about: pageDetails.about || null,
      phone: pageDetails.phone || null,
      address: pageDetails.location ? 
        `${pageDetails.location.street || ''}, ${pageDetails.location.city || ''}, ${pageDetails.location.country || ''}`.trim() : null,
      website: pageDetails.website || null,
      facebookPageId: matchingPage.id,
      instagramBusinessAccountId: matchingPage.instagram_business_account?.id || null,
      page_access_token: tokenData.access_token
    };

    await updateDoc(userDocRef, {
      MetaSettings: metaSettings
    });

    console.log('MetaSettings opdateret:', metaSettings);
    alert('MetaSettings er blevet opdateret!');
    
  } catch (error) {
    console.error('Fejl ved opdatering af MetaSettings:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    // Fjern bruger fra loading state
    metaSettingsLoading.value.delete(user.id);
  }
};

const trialLoading = ref(new Set());

const toggleTrialPeriod = async (user) => {
  try {
    trialLoading.value.add(user.id);
    const userDocRef = doc(db, "users", user.id);
    
    if (!user.trialPeriod || !user.trialPeriod.active) {
      // Start prøveperiode
      const trialEnd = new Date();
      trialEnd.setDate(trialEnd.getDate() + 14); // 14 dages prøveperiode
      
      await updateDoc(userDocRef, {
        active: true, // Sørg for at brugeren forbliver aktiv
        trialPeriod: {
          active: true,
          startDate: Timestamp.now(),
          endDate: Timestamp.fromDate(trialEnd),
          activated: true
        }
      });
    } else {
      // Fjern kun prøveperioden, bevar aktiv status
      await updateDoc(userDocRef, {
        'trialPeriod.active': false
      });
    }
  } catch (error) {
    console.error('Fejl ved håndtering af prøveperiode:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    trialLoading.value.delete(user.id);
  }
};

const isTrialExpired = (user) => {
  if (!user.trialPeriod?.endDate) return false;
  const endDate = user.trialPeriod.endDate.toDate();
  return endDate < new Date();
};

// Opdater badge logikken
const getBadgeColor = (user) => {
  if (!user.active) return 'danger'; // Helt inaktiv bruger
  if (user.trialPeriod?.active) return 'warning'; // I prøveperiode
  return 'success'; // Normal aktiv bruger
};

const getBadgeText = (user) => {
  if (!user.active) return 'Inaktiv';
  if (user.trialPeriod?.active) {
    return `Prøveperiode (Udløber: ${user.trialPeriod.endDate.toDate().toLocaleDateString()})`;
  }
  return 'Aktiv';
};

// Definér toggleUserAccount funktionen
const toggleUserAccount = async (user) => {
  try {
    // Initialiser isLoading som et objekt hvis det ikke allerede er det
    if (!isLoading.value || typeof isLoading.value !== 'object') {
      isLoading.value = {};
    }
    
    // Sæt loading state for denne specifikke bruger
    isLoading.value[`toggle-${user.id}`] = true;

    // Opdater brugerens status i Firestore
    const userRef = doc(db, "users", user.id);
    await updateDoc(userRef, {
      active: !user.active,
      disabled: user.active,
      lastModified: new Date(),
      modifiedBy: store.state.activeUser.id
    });

    // Kald backend via HTTP
    const response = await fetch('https://us-central1-toft-d4f39.cloudfunctions.net/toggleUserStatus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: user.id,
        disabled: user.active
      })
    });

    if (!response.ok) {
      throw new Error('Kunne ikke opdatere bruger status');
    }

    const result = await response.json();

    // Opdater lokal bruger status
    user.active = !user.active;
    user.disabled = !user.active;

    console.log('Brugerstatus opdateret:', result);
    alert(result.message || 'Bruger status opdateret');

  } catch (error) {
    console.error('Fejl ved ændring af brugerstatus:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    // Fjern loading state for denne specifikke bruger
    if (isLoading.value && typeof isLoading.value === 'object') {
      isLoading.value[`toggle-${user.id}`] = false;
    }
  }
};

const { sendPasswordResetForUser } = usePasswordManagement();
const passwordResetLoading = ref(new Set());

const handlePasswordReset = async (user) => {
  try {
    passwordResetLoading.value.add(user.id);
    
    // Verificer at brugeren har en email
    if (!user.email) {
      throw new Error('Bruger har ingen email-adresse');
    }
    
    // Verificer at den aktive bruger har tilladelse (rolle 1 eller 2)
    const currentUserRole = store.state.activeUser.role;
    if (currentUserRole > 2) {
      throw new Error('Du har ikke tilladelse til at nulstille adgangskoder');
    }
    
    // For rolle 2 (admin), verificer at de kun nulstiller for deres egne brugere
    if (currentUserRole === 2 && user.createdBy !== store.state.activeUser.id) {
      throw new Error('Du kan kun nulstille adgangskoder for dine egne brugere');
    }

    await sendPasswordResetForUser(user.email);
    alert('Nulstillingslink er sendt til brugerens email');
    
  } catch (error) {
    console.error('Fejl ved nulstilling af adgangskode:', error);
    alert(`Fejl: ${error.message}`);
  } finally {
    passwordResetLoading.value.delete(user.id);
  }
};

// Tilføj denne computed property
const getUserCustomerCount = computed(() => {
  const counts = {};
  users.value.forEach(user => {
    if (user.role === ROLE_USER) { // Rolle 3
      const managerId = user.createdBy;
      counts[managerId] = (counts[managerId] || 0) + 1;
    }
  });
  return counts;
});

const showScheduledPostsModal = ref(false);
const selectedUserId = ref(null);
const scheduledPosts = ref([]);
const scheduledPostsSend = ref([]);

const openScheduledPostsModal = async (userId) => {
  try {
    console.log('Åbner modal for bruger:', userId);
    selectedUserId.value = userId;
    
    const db = getFirestore();
    
    // Tjek om currentUser er tilgængelig
    if (!auth.currentUser) {
      throw new Error('Ingen bruger er logget ind');
    }
    
    console.log('Henter data for currentUser:', auth.currentUser.uid);
    
    // Hent brugerens rolle med fejlhåndtering
    let userRole;
    try {
      const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
      if (!userDoc.exists()) {
        throw new Error('Bruger dokument ikke fundet');
      }
      userRole = userDoc.data()?.role;
      console.log('Bruger rolle:', userRole);
    } catch (error) {
      console.error('Fejl ved hentning af brugerrolle:', error);
      throw new Error('Kunne ikke hente brugerrolle: ' + error.message);
    }
    
    // Opret queries baseret på rolle
    let postsQuery, postSendQuery;
    
    if (userRole === 3) {
      console.log('Henter opslag for almindelig bruger');
      postsQuery = query(collection(db, "posts"), 
        where("userId", "==", auth.currentUser.uid));
      postSendQuery = query(collection(db, "postSend"), 
        where("userId", "==", auth.currentUser.uid));
    } else {
      console.log('Henter opslag for admin/superadmin');
      postsQuery = query(collection(db, "posts"), 
        where("id", "==", userId));
      postSendQuery = query(collection(db, "postSend"), 
        where("id", "==", userId));
    }

    console.log('Starter datahentning...');
    
    // Hent data fra begge collections med fejlhåndtering
    try {
      const [postsSnapshot, postSendSnapshot] = await Promise.all([
        getDocs(postsQuery),
        getDocs(postSendQuery)
      ]);

      console.log('Rå posts data:', postsSnapshot.docs.map(doc => doc.data()));
      console.log('Rå postSend data:', postSendSnapshot.docs.map(doc => doc.data()));

      // Behandl planlagte opslag (posts)
      scheduledPosts.value = postsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          collection: 'posts',
          createdAt: data.createdAt?.toDate?.() || new Date(),
          scheduledTime: data.publishTime && data.publishDate ? 
            `${data.publishDate}T${data.publishTime.HH}:${data.publishTime.MM}:00` : 
            null,
          status: 'scheduled',
          mediaUrls: data.children?.[0]?.images || [],
          headline: data.children?.[0]?.headline || '',
          caseUrl: data.caseUrl || '',
          postText: data.text || ''
        };
      });

      // Behandl publicerede opslag (postSend)
      scheduledPostsSend.value = postSendSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          collection: 'postSend',
          publishedAt: data.publishedAt?.toDate?.() || null,
          status: 'published',
          mediaUrls: data.children?.[0]?.images || [],
          headline: data.children?.[0]?.headline || '',
          caseUrl: data.caseUrl || '',
          postText: data.text || '',
          analytics: data.analytics || {
            facebook: {
              post_clicks: 0,
              post_engagements: 0,
              post_impressions_unique: 0,
              post_reactions_like_total: 0
            }
          },
          facebookLink: data.facebookLink || null
        };
      });

      console.log('Behandlede opslag:', {
        planlagte: scheduledPosts.value.length,
        publicerede: scheduledPostsSend.value.length,
        planlagteData: scheduledPosts.value,
        publiceredeData: scheduledPostsSend.value
      });

      showScheduledPostsModal.value = true;
      
    } catch (error) {
      console.error('Fejl ved datahentning:', error);
      throw new Error('Kunne ikke hente opslag: ' + error.message);
    }
    
  } catch (error) {
    console.error('Detaljeret fejl:', error);
    console.error('Fejl stack:', error.stack);
    alert('Der opstod en fejl ved hentning af opslag: ' + error.message);
  } finally {
    isLoading.value = false;
  }
};

// Tilføj hjælpefunktion til at konvertere Firestore timestamps
const convertTimestamps = (data) => {
  if (!data) return data;
  
  const converted = { ...data };
  
  // Liste over felter der skal konverteres
  const timestampFields = ['createdAt', 'publishedAt', 'lastUpdated', 'processingStartTime', 'processingEndTime'];
  
  timestampFields.forEach(field => {
    if (converted[field] && typeof converted[field].toDate === 'function') {
      converted[field] = converted[field].toDate();
    }
  });
  
  return converted;
};

// Opdater deletePost funktionen med bedre fejlhåndtering
const deletePost = async (post) => {
  try {
    console.log('Forsøger at slette opslag:', post);
    
    if (!post.id || !selectedUserId.value) {
      throw new Error('Mangler post ID eller bruger ID');
    }

    const collectionName = post.collection || 'posts';
    const postRef = doc(db, 'users', selectedUserId.value, collectionName, post.id);
    
    await deleteDoc(postRef);
    console.log('Opslag slettet:', post.id);
    
    // Opdater lokale arrays
    if (collectionName === 'posts') {
      scheduledPosts.value = scheduledPosts.value.filter(p => p.id !== post.id);
    } else {
      scheduledPostsSend.value = scheduledPostsSend.value.filter(p => p.id !== post.id);
    }

    console.log('Lokale arrays opdateret');

  } catch (error) {
    console.error('Fejl ved sletning af opslag:', error);
    alert('Der opstod en fejl ved sletning af opslaget: ' + error.message);
  }
};

// Tilføj metode til at se detaljer for et opslag
const viewPost = (post) => {
  console.log('Vis detaljer for opslag:', post);
  // Implementer visning af detaljer her
};

const closeScheduledPostsModal = () => {
  showScheduledPostsModal.value = false;
  selectedUserId.value = null;
  scheduledPosts.value = [];
  scheduledPostsSend.value = [];
};

</script>

<template>
  <div class="card">
    <div v-if="isLoading">Indlæser...</div>
    <div v-else-if="!currentUser">
      <p>Omdirigerer til login...</p>
    </div>
    <div v-else>
      <!-- Søgebjælke og knapper -->
      <div class="d-flex justify-content-between align-items-center px-3 py-2">
        <div class="search-bar flex-grow-1 me-3">
          <!-- Her kunne du eventuelt erstatte input med en custom Argon input component -->
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Søg efter en bruger..."
            class="form-control search-input"
          />
        </div>

        <div class="d-flex gap-2">
          <argon-button
            color="secondary"
            size="sm"
            variant="outline"
            class="custom-button"
            v-if="currentUser.role === 1 && showAllUserListButton"
            @click="allUserLists()"
          >
            Vis alle brugere
          </argon-button>

          <argon-button
            color="success"
            size="sm"
            variant="outline"
            class="custom-button"
            @click="openAddCompanyModal"
          >
            Tilføj virksomhed
          </argon-button>
        </div>
      </div>

      <!-- Tabel indhold -->
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <!-- Flyt denne kolonne op foran On/Off -->
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Planlagte Opslag
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  On/Off
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Bruger
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Actions
                </th>
                <th v-if="currentUser.role === 1" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Antal kunder
                </th>
                <th v-if="currentUser.role === 1" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Brugerrolle
                </th>
                <th v-if="currentUser.role <= 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Auto Pilot Quick Setup
                </th>
                <th v-if="currentUser.role <= 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Tilknyt Facebook & Instagram
                </th>
                <th v-if="currentUser.role <= 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Prøveperiode (14 dage)
                </th>
                <th v-if="currentUser.role <= 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Password Reset
                </th>
                <th v-if="currentUser.role === 1 || currentUser.role === 2" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Deaktiver | Slet bruger
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in filteredUsers" :key="user.id">
                <!-- Flyt denne celle op foran On/Off -->
                <td class="align-middle">
                  <argon-button
                    color="info"
                    size="sm"
                    variant="gradient"
                    class="custom-button"
                    @click="openScheduledPostsModal(user.id)"
                  >
                    Vis planlagte opslag
                  </argon-button>
                </td>
                <td class="align-middle text-center text-sm">
                  <argon-badge
                    :color="user.isOnline ? 'success' : 'dark'"
                    variant="gradient"
                    size="sm"
                    class="badge-sm"
                  >
                    {{ user.isOnline ? "Online" : "Offline" }}
                  </argon-badge>
                </td>
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ user.name }}</h6>
                      <p class="text-xs text-secondary mb-0">{{ user.email }}</p>
                      <p class="text-xxs text-secondary mb-0 opacity-7">ID: {{ user.id }}</p>
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  <div class="d-flex gap-2">
                    <argon-button
                      color="azure"
                      size="sm"
                      variant="gradient"
                      class="custom-button"
                      @click="accessUserProfile(user.id)"
                    >
                      Profil
                    </argon-button>
                    <argon-button
                      v-if="user.role === 2"
                      color="navy"
                      size="sm"
                      variant="gradient"
                      class="custom-button"
                      @click="allUsers(user)"
                    >
                      Alle brugere
                    </argon-button>
                  </div>
                </td>
                <td v-if="currentUser.role === 1">
                  <div class="d-flex px-2 py-1" v-if="user.role === ROLE_MANAGER">
                    <span class="text-xs text-secondary">
                      {{ getUserCustomerCount[user.id] || 0 }} tilknyttede brugere
                    </span>
                  </div>
                  <div v-else></div>
                </td>
                <td v-if="currentUser.role === 1">
                  <div class="d-flex gap-2 align-items-center">
                    <div class="d-flex gap-1">
                      <argon-button
                        :color="user.role === 2 ? 'primary' : 'secondary'"
                        size="sm"
                        variant="gradient"
                        class="custom-button"
                        v-if="user.role !== 2"
                        @click="markUserRole(user, 2)"
                      >
                        Bruger
                      </argon-button>
                      <argon-button
                        :color="user.role === 3 ? 'primary' : 'secondary'"
                        size="sm"
                        variant="gradient"
                        class="custom-button"
                        v-if="user.role !== 3"
                        @click="markUserRole(user, 3)"
                      >
                        Manager
                      </argon-button>
                    </div>
                  </div>
                </td>
                <td v-if="currentUser.role <= 2" class="align-middle">
                  <!-- Quick Setup knap -->
                  <argon-button
                    v-if="user.role === 3 && 
                          user.dealerId && 
                          !hasAutoPost(user.id)"
                    color="success"
                    size="sm"
                    variant="gradient"
                    class="custom-button"
                    @click="setupAutoPosts(user)"
                  >
                    Quick setup
                  </argon-button>

                  <!-- Status tags -->
                  <div v-if="hasAutoPost(user.id)" class="d-flex gap-2">
                    <argon-badge
                      v-if="user.MetaSettings?.facebookPageId"
                      color="success"
                      variant="gradient"
                      size="sm"
                      class="badge-sm"
                    >
                      Auto Pilot aktiveret
                    </argon-badge>
                    
                    <argon-badge
                      v-else
                      color="danger"
                      variant="gradient"
                      size="sm"
                      class="badge-sm"
                    >
                      Facebook ikke tilknyttet
                    </argon-badge>
                  </div>
                </td>
                <td v-if="currentUser.role <= 2" class="align-middle">
                  <!-- Meta Settings knap -->
                  <argon-button
                    v-if="user.role === 3 && 
                          user.dealerId && 
                          !user.MetaSettings?.facebookPageId"
                    color="info"
                    size="sm"
                    variant="gradient"
                    class="custom-button"
                    @click="connectMetaSettings(user)"
                    :disabled="metaSettingsLoading.has(user.id)"
                  >
                    <template v-if="metaSettingsLoading.has(user.id)">
                      <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      Tilknytter...
                    </template>
                    <template v-else>
                      Tilknyt Facebook
                    </template>
                  </argon-button>

                  <!-- Status tags -->
                  <div v-if="user.MetaSettings?.facebookPageId" class="d-flex gap-2">
                    <argon-badge
                      color="success"
                      variant="gradient"
                      size="sm"
                      class="badge-sm"
                    >
                      Facebook tilknyttet
                    </argon-badge>
                  </div>
                </td>
                <td v-if="currentUser.role <= 2" class="align-middle">
                  <div class="d-flex align-items-center gap-3">
                    <!-- Switch with tooltip -->
                    <div class="form-check form-switch" v-tooltip="'Aktiver eller deaktiver prøveperiode'">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :id="'trialSwitch_' + user.id"
                        :checked="user.trialPeriod?.active"
                        @change="toggleTrialPeriod(user)"
                        :disabled="trialLoading.has(user.id)"
                      >
                    </div>

                    <!-- Badge med status og dato -->
                    <argon-badge
                      :color="getBadgeColor(user)"
                      variant="gradient"
                      size="sm"
                      class="badge-sm"
                    >
                      {{ user.trialPeriod?.active 
                        ? `Prøveperiode (${user.trialPeriod.endDate.toDate().toLocaleDateString()})` 
                        : 'Aktiv' 
                      }}
                    </argon-badge>
                  </div>
                </td>
                <td v-if="currentUser.role <= 2" class="align-middle">
                  <div class="d-flex gap-2">
                    <!-- Ny Password Reset knap -->
                    <argon-button
                      v-if="currentUser.role <= 2"
                      color="info"
                      size="sm"
                      variant="gradient"
                      class="custom-button"
                      @click="handlePasswordReset(user)"
                      :disabled="passwordResetLoading.has(user.id)"
                    >
                      <template v-if="passwordResetLoading.has(user.id)">
                        <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Sender...
                      </template>
                      <template v-else>
                        Nulstil kode
                      </template>
                    </argon-button>
                  </div>
                </td>
                <td v-if="currentUser.role === 1 || currentUser.role === 2" class="align-middle">
                  <div class="d-flex gap-2">
                    <!-- Deaktiver/Aktiver knap -->
                    <argon-button
                      :color="user.disabled ? 'success' : 'warning'"
                      size="sm"
                      variant="gradient"
                      class="custom-button"
                      @click="toggleUserAccount(user)"
                      :disabled="isLoading[`toggle-${user.id}`]"
                    >
                      <template v-if="isLoading[`toggle-${user.id}`]">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </template>
                      <template v-else>
                        {{ user.disabled ? 'Aktiver' : 'Deaktiver' }}
                      </template>
                    </argon-button>

                    <!-- Slet knap med rød gradient -->
                    <argon-button
                      color="danger"
                      size="sm"
                      variant="gradient"
                      class="custom-button"
                      @click="deleteUserConfirmation(user)"
                      :disabled="isLoading[`delete-${user.id}`]"
                      style="background: linear-gradient(135deg, rgba(231, 76, 60, 0.9), rgba(192, 57, 43, 0.9)) !important;"
                    >
                      <template v-if="isLoading[`delete-${user.id}`]">
                        <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Sletter...
                      </template>
                      <template v-else>
                        Slet
                      </template>
                    </argon-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Modal -->
      <div v-if="showAddCompanyModal" class="modal-overlay">
        <div class="modal-container">
          <CompanyAddComponent @close="closeAddCompanyModal" />
        </div>
      </div>

      <Teleport to="body">
        <OrderList
          v-if="showScheduledPostsModal"
          :posts="scheduledPosts"
          :postsSend="scheduledPostsSend"
          :userId="selectedUserId"
          :is-open="showScheduledPostsModal"
          @close="closeScheduledPostsModal"
          @delete-post="deletePost"
          @view-post="viewPost"
        />
      </Teleport>
    </div>
  </div>
</template>

<style scoped>
/* Card base */
.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
}

/* Tabel */
.table {
  background: transparent;
  color: #ffffff;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.table th {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 20px 15px;
  background: rgba(255, 255, 255, 0.05);
  border: none;
}

.table td {
  padding: 15px;
  border: none;
  backdrop-filter: blur(5px);
}

.table tbody tr {
  background: rgba(255, 255, 255, 0.03);
  transition: background-color 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateZ(0);
}

.table tbody tr:hover {
  background: rgba(255, 255, 255, 0.07);
}

/* Søgefelt */
.search-input {
  background: rgba(255, 255, 255, 0.07);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 12px;
  padding: 12px 20px;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input:focus {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
  outline: none;
}

/* Fælles knap styling */
:deep(.custom-button) {
  background: rgba(255, 255, 255, 0.08) !important;
  backdrop-filter: blur(8px) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  padding: 8px 16px !important;
  color: white !important;
  font-weight: 500 !important;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1) !important;
  transform: translateZ(0);
  will-change: transform, background-color;
  box-shadow: 
    0 2px 5px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.05) !important;
}

:deep(.custom-button:hover) {
  background: rgba(255, 255, 255, 0.15) !important;
}

/* Farve varianter med subtil gradient */
:deep(.custom-button.btn-azure) {
  background: linear-gradient(
    135deg, 
    rgba(33, 150, 243, 0.15), 
    rgba(33, 150, 243, 0.25)
  ) !important;
}

:deep(.custom-button.btn-success) {
  background: linear-gradient(
    135deg, 
    rgba(66, 186, 150, 0.15), 
    rgba(66, 186, 150, 0.25)
  ) !important;
}

:deep(.custom-button.btn-danger) {
  background: linear-gradient(
    135deg, 
    rgba(245, 54, 92, 0.15), 
    rgba(245, 54, 92, 0.25)
  ) !important;
}

:deep(.custom-button.btn-secondary) {
  background: linear-gradient(
    135deg, 
    rgba(130, 214, 255, 0.15), 
    rgba(130, 214, 255, 0.25)
  ) !important;
}

/* Hover effekter for farve varianter */
:deep(.custom-button.btn-azure:hover),
:deep(.custom-button.btn-success:hover),
:deep(.custom-button.btn-danger:hover),
:deep(.custom-button.btn-secondary:hover) {
  opacity: 0.9 !important;
}

/* Badge */
.badge-sm {
  backdrop-filter: blur(4px);
  border-radius: 20px;
  padding: 5px 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  z-index: 1000;
  animation: fadeIn 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.modal-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  animation: slideUp 0.3s cubic-bezier(0.2, 0, 0.2, 1);
  padding: 20px;
  position: relative;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}

/* Tilføj styling for badge container */
.d-flex.gap-2 {
  display: flex;
  gap: 0.5rem;
}

/* Opdater badge styling */
.badge-sm {
  backdrop-filter: blur(4px);
  border-radius: 20px;
  padding: 5px 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Success badge styling */
:deep(.badge-sm.badge-success) {
  background: linear-gradient(
    135deg, 
    rgba(46, 204, 113, 0.1), 
    rgba(46, 204, 113, 0.2)
  ) !important;
  border: 1px solid rgba(46, 204, 113, 0.15) !important;
}

/* Danger badge styling */
:deep(.badge-sm.badge-danger) {
  background: linear-gradient(
    135deg, 
    rgba(231, 76, 60, 0.1), 
    rgba(231, 76, 60, 0.2)
  ) !important;
  border: 1px solid rgba(231, 76, 60, 0.15) !important;
}

/* Tilføj spinner styling */
.spinner-border {
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
  border: 0.2em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

/* Switch styling */
.form-switch {
  padding-left: 2.5em;
}

.form-check-input {
  cursor: pointer;
  width: 40px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}

.form-check-input:checked {
  background-color: #2ecc71;
  border-color: #27ae60;
}

.form-check-input:focus {
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 0 0.2rem rgba(46, 204, 113, 0.25);
}

.form-check-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.875rem;
  cursor: pointer;
}
</style>
