// src/router/index.js

import { createRouter, createWebHistory } from "vue-router";
import { auth, db } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import store from "@/store"; // Importer din Vuex store

// Importer dine komponenter
import SweetAlerts from "../views/pages/SweetAlerts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Signin from "../views/auth/signin.vue";
import Tables from "../views/pages/Tables.vue";
import UserProfile from "../views/pages/UserProfile.vue";
import Profile from "../views/pages/Profile.vue";
import Calendar from "../views/pages/Calendar.vue";
import Settings from "../views/pages/Settings.vue";
import BlogOverview from '@/views/pages/BlogOverview.vue'
import BlogPost from '@/views/pages/BlogPost.vue'

// Definer roller som konstanter for bedre vedligeholdelse
const ROLE_ADMIN = 1;
const ROLE_USER = 3;
const ROLE_MANAGER = 2

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/authentication/signin",
  },
  // Profilruter
  {
    path: "/profile/:id?",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      allowAdminAccess: true
    }
  },
  {
    path: "/pages/sweet-alerts",
    name: "Sweet Alerts",
    component: SweetAlerts,
    meta: { requiresAuth: true },
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
    meta: { requiresAuth: true },
  },
  {
    path: "/pages/calendar",
    name: "Calendar",
    component: Calendar,
    meta: { requiresAuth: true },
  },
  {
    path: "/authentication/signin/",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/pages/tables",
    name: "Tables",
    component: Tables,
    meta: { requiresAuth: true, requiresAdminAndSubAdmin: true }, // Kræver admin-rolle (role 1)
  },
  {
    path: "/pages/settings",
    name: "Settings",
    component: Settings,
    meta: { requiresAuth: true, requiresAdminAndSubAdmin: true }, // Kræver admin-rolle (role 1)
  },
  {
    path: "/user-profile/:userId",
    name: "UserProfile",
    component: UserProfile,
    meta: { requiresAuth: true, requiresAdmin: true }, // Kræver admin-rolle (role 1)
  },
  {
    path: '/blog',
    name: 'BlogOverview',
    component: BlogOverview
  },
  {
    path: '/blog/:slug/:id',
    name: 'BlogPost',
    component: BlogPost,
    meta: { requiresAuth: false }
  },
  {
    path: '/saved-filters',
    name: 'SavedFilters',
    component: () => import('@/views/pages/SavedFilters.vue'),
    meta: { requiresAuth: true }
  },
  // Wildcard route for 404 Not Found
  {
    path: "/:pathMatch(.*)*",
    redirect: "/authentication/signin",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Opdater auth initialization
let authInitialized = false;
const authPromise = new Promise((resolve) => {
  onAuthStateChanged(auth, async (user) => {
    if (!authInitialized) {
      authInitialized = true;
      
      if (user) {
        try {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          const userData = userDoc.data();
          
          if (userData) {
            await store.dispatch("setCurrentUser", { 
              ...userData, 
              id: user.uid 
            });
          }
        } catch (error) {
          console.error("Fejl ved initial auth:", error);
        }
      }
      
      store.commit('SET_AUTH_LOADING', false);
      resolve(user);
    }
  });
});

// Opdater beforeEach guard
router.beforeEach(async (to, from, next) => {
  if (!authInitialized) {
    await authPromise;
  }

  const currentUser = store.state.activeUser;
  
  // Håndter auth beskyttede routes
  if (!currentUser && to.meta.requiresAuth) {
    next("/authentication/signin");
    return;
  }

  // Speciel håndtering for profil routes
  if (to.name === "Profile" || to.name === "UserProfile") {
    const targetUserId = to.params.id || to.params.userId;
    const isAdminOrSubAdmin = currentUser?.role === 1 || currentUser?.role === 2;
    
    if (targetUserId) {
      // Hvis der er et target ID og brugeren er admin/subadmin
      if (isAdminOrSubAdmin) {
        next();
      } else if (targetUserId === currentUser?.id) {
        next();
      } else {
        next("/profile");
      }
      return;
    }
  }

  next();
});

export default router;