import axios from 'axios';
import { getFirestore, doc, getDoc, collection, collectionGroup } from 'firebase/firestore';

// Konstant for API URL
const API_URL = "https://us-central1-toft-d4f39.cloudfunctions.net/correctText";

// Funktion til at hente bildata fra dealerCars subcollection
const getCarData = async (caseId, contextUserId, activeUserId, canAccessOtherUsers) => {
  try {
    console.log('Forsøger at hente bildata med caseId:', caseId, 'og contextUserId:', contextUserId);

    const db = getFirestore();
    let carRef;

    if (canAccessOtherUsers) {
      if (contextUserId !== activeUserId) {
        const userDocRef = doc(db, 'users', contextUserId);
        carRef = doc(userDocRef, 'dealerCars', caseId.toString());
      } else {
        carRef = doc(collectionGroup(db, 'dealerCars'), caseId.toString());
      }
    } else {
      const userDocRef = doc(db, 'users', contextUserId);
      carRef = doc(userDocRef, 'dealerCars', caseId.toString());
    }

    const carDoc = await getDoc(carRef);
    
    if (carDoc.exists()) {
      const carData = carDoc.data();
      console.log('Fundet bildata:', carData);
      return carData;
    }
    
    console.log('Bil dokument ikke fundet');
    return null;
  } catch (error) {
    console.error('Fejl ved hentning af bildata:', error);
    return null;
  }
};

// Generel tekst korrektion
export const correctEventText = async (messages, postData) => {
  try {
    console.log('PostData modtaget:', postData);

    // Hvis det er en bil-post, hent bildata først
    let carData = null;
    if (postData?.type === 'singlePost' && postData?.caseId) {
      const carDataResponse = await axios.get(`https://us-central1-toft-d4f39.cloudfunctions.net/getSpecificCarData`, {
        params: {
          userId: postData.id,
          carId: postData.caseId
        }
      });

      if (carDataResponse.data.success) {
        carData = carDataResponse.data.data;
        console.log('Hentet bildata:', carData);
      } else {
        console.error('Fejl ved hentning af specifik bildata:', carDataResponse.data.error);
        return null;
      }
    }

    // Hvis vi har bildata, brug constructCarMessages med postStatus fra postData
    if (carData) {
      const carMessages = constructCarMessages(carData, messages[1].content, postData.postStatus || 'active');
      console.log('Konstruerede bil-beskeder:', carMessages);
      
      const response = await axios.post(API_URL, {
        action: 'correct',
        messages: carMessages,
        carData: carData
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      return { correctedText: response.data.correctedText };
    }

    // Hvis ikke bil-data, fortsæt med almindelig korrektion
    const response = await axios.post(API_URL, {
      action: 'correct',
      messages: messages
    }, {
      headers: { 'Content-Type': 'application/json' }
    });
    
    if (response.data && response.data.correctedText) {
      return { correctedText: response.data.correctedText };
    } else if (response.data && response.data.error) {
      throw new Error(response.data.error);
    } else {
      throw new Error('Uventet svarformat fra serveren');
    }
  } catch (error) {
    console.error('Fejl under processering af tekst:', error);
    throw error;
  }
};

// Bil-specifikte beskeder
const constructCarMessages = (carData, currentText, status) => {
  console.log('Konstruerer bil-beskeder med:', { carData, currentText, status }); // Debug log
  
  const messages = [
    {
      role: "system",
      content: `Du er en erfaren bilsælger og social media manager. Din opgave er at skabe fængende og salgsorienterede opslag til sociale medier om biler. 
                Regler:
                - Brug emojis naturligt og moderat
                - Hold teksten kort og fængende, max 280 tegn
                - Tilpas tonen og indholdet specifikt til den pågældende bil
                - Skab et opslag, der opfordrer til interaktion og klik
                - Undgå at bruge formateringsmærker som **, [], eller overskrifter
                - Skriv teksten, så den er klar til at blive kopieret direkte ind i et socialt medie opslag
                - Inkluder en kort, fængende beskrivelse af bilen og dens vigtigste features
                - Afslut med en opfordring til handling (CTA)`
    }
  ];

  // Konverter bilens felter til læsbar tekst
  const carFields = Object.entries(carData.fields)
    .map(([key, value]) => `${key}: ${value}`)
    .join('\n');

  // Tjek for eksisterende link fra selink.dk
  const existingLink = currentText.match(/https:\/\/selink\.dk\S*/);
  const linkToUse = existingLink ? existingLink[0] : '[]';

  messages.push({
    role: "user",
    content: `Lav et fængende socialt medie opslag baseret på følgende information:
              Mærke og model: ${carData.fields.Mærke} ${carData.fields.Model}
              Overskrift: ${carData.headline}
              Beskrivelse: ${carData.description}
              År: ${carData.fields.Årgang}
              Pris: ${carData.price}
              Status: ${status}
              
              Yderligere detaljer:
              ${carFields}
              
              Nuværende tekst (skal forbedres):
              ${currentText}
              
              Link: ${linkToUse}
              
              Husk at følge reglerne i system-prompten, og lav en tekst der er klar til at blive postet direkte.`
  });

  return messages;
};
