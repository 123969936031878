// src/utils/videoGenerator.js
import axios from 'axios';
import { API_CONFIG } from '@/firebase';

const POLLING_INTERVAL = 2000; // 2 sekunder mellem hver status check
const MAX_DURATION = 3 * 60 * 1000; // 3 minutter i millisekunder

export async function generateVideo(car, designConfig = {}, statusCallback) {
  try {
    console.log('generateVideo kaldt med:', {
      car,
      designConfig,
      hasStatusCallback: !!statusCallback
    });

    statusCallback?.({
      status: 'INITIATING',
      message: 'Starter video generering...'
    });

    // Send initial request
    const response = await axios.post(
      `${API_CONFIG.baseUrl}/generateVideoApi`,
      { car, designConfig },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_CONFIG.apiKey
        }
      }
    );

    console.log('Initial response:', response.data);

    if (!response.data?.data?.renderId) {
      throw new Error('Ingen render ID modtaget fra backend');
    }

    const renderId = response.data.data.renderId;
    console.log('Modtaget renderId:', renderId);
    
    // Start polling for status
    const startTime = Date.now();
    let elapsedTime = 0;
    let dots = '';
    
    while (elapsedTime < MAX_DURATION) {
      elapsedTime = Date.now() - startTime;
      const progress = Math.min(100, Math.round((elapsedTime / MAX_DURATION) * 100));
      
      // Roter mellem 1-3 prikker for at vise aktivitet
      dots = '.'.repeat((Math.floor(elapsedTime / 500) % 3) + 1);
      
      statusCallback?.({
        status: 'PROCESSING',
        message: `Genererer video${dots}`,
        progress, // Tilføj fremskridt i procent
        elapsedTime, // Tilføj forløbet tid i ms
        startTime // Tilføj starttidspunkt
      });

      try {
        const statusResponse = await axios.get(
          `${API_CONFIG.baseUrl}/checkVideoStatus`,
          {
            params: { renderId },
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': API_CONFIG.apiKey
            }
          }
        );

        console.log('Status response:', statusResponse.data);

        // Tjek om videoen er færdig
        if (statusResponse.data?.data?.status === 'COMPLETED') {
          statusCallback?.({
            status: 'COMPLETED',
            message: 'Video er klar!'
          });
          
          return {
            videoUrl: statusResponse.data.data.videoUrl,
            thumbnailUrl: statusResponse.data.data.thumbnailUrl
          };
        }
        
        // Hvis status er FAILED
        if (statusResponse.data?.data?.status === 'FAILED') {
          throw new Error('Video generering fejlede: ' + (statusResponse.data?.error || 'Ukendt fejl'));
        }

        // Fortsæt polling hvis videoen stadig processerer
        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));

      } catch (error) {
        console.error('Fejl ved status check:', error);
        if (elapsedTime >= MAX_DURATION - POLLING_INTERVAL) {
          throw new Error('Kunne ikke hente video status: ' + error.message);
        }
        await new Promise(resolve => setTimeout(resolve, POLLING_INTERVAL));
      }
    }

    throw new Error('Timeout: Video generering tog længere end 3 minutter');

  } catch (error) {
    console.error('Fejl i generateVideo:', error);
    statusCallback?.({
      status: 'ERROR',
      message: `Fejl: ${error.message}`,
      error
    });
    throw error;
  }
}