<template>
  <Teleport to="body">
    <div v-if="isOpen" class="modal-backdrop show"></div>
    <div v-if="isOpen" class="modal show d-block" tabindex="-1" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Social Media Analytics</h5>
            <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- Analytics Cards Row -->
            <div class="row">
              <div class="mb-4 col-xl-3 col-sm-6 mb-xl-0">
                <div class="mb-3 card">
                  <div class="p-3 card-body">
                    <div class="d-flex flex-row-reverse justify-content-between">
                      <div class="text-center shadow icon icon-shape bg-gradient-primary">
                        <i class="fab fa-facebook-f text-lg opacity-10"></i>
                      </div>
                      <div class="">
                        <div class="numbers">
                          <p class="mb-0 text-sm text-uppercase font-weight-bold">Facebook Reach</p>
                          <h5 class="mb-0 font-weight-bolder">
                            {{ facebookData.reach.toLocaleString('da-DK') }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-4 col-xl-3 col-sm-6 mb-xl-0">
                <div class="mb-3 card">
                  <div class="p-3 card-body">
                    <div class="d-flex flex-row-reverse justify-content-between">
                      <div class="text-center shadow icon icon-shape bg-gradient-danger">
                        <i class="fab fa-instagram text-lg opacity-10"></i>
                      </div>
                      <div class="">
                        <div class="numbers">
                          <p class="mb-0 text-sm text-uppercase font-weight-bold">Instagram Reach</p>
                          <h5 class="mb-0 font-weight-bolder">
                            {{ instagramData.reach.toLocaleString('da-DK') }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-4 col-xl-3 col-sm-6 mb-xl-0">
                <div class="mb-3 card">
                  <div class="p-3 card-body">
                    <div class="d-flex flex-row-reverse justify-content-between">
                      <div class="text-center shadow icon icon-shape bg-gradient-success">
                        <i class="fas fa-chart-line text-lg opacity-10"></i>
                      </div>
                      <div class="">
                        <div class="numbers">
                          <p class="mb-0 text-sm text-uppercase font-weight-bold">FB Impressions</p>
                          <h5 class="mb-0 font-weight-bolder">
                            {{ facebookData.postImpressionsUnique.toLocaleString('da-DK') }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-sm-6">
                <div class="mb-3 card">
                  <div class="p-3 card-body">
                    <div class="d-flex flex-row-reverse justify-content-between">
                      <div class="text-center shadow icon icon-shape bg-gradient-warning">
                        <i class="fas fa-chart-bar text-lg opacity-10"></i>
                      </div>
                      <div class="">
                        <div class="numbers">
                          <p class="mb-0 text-sm text-uppercase font-weight-bold">IG Impressions</p>
                          <h5 class="mb-0 font-weight-bolder">
                            {{ instagramData.postImpressionsUnique.toLocaleString('da-DK') }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Doughnut Chart Row -->
            <div class="row mt-4">
              <div class="col-lg-5 col-12">
                <div class="card">
                  <div class="p-3 card-body">
                    <div class="row">
                      <div class="text-center col-12">
                        <div class="chart">
                          <canvas ref="doughnutChart" height="200"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-7 col-12">
                <div class="card">
                  <div class="p-3 card-body">
                    <div class="table-responsive">
                      <table class="table mb-0 align-items-center">
                        <tbody>
                          <tr>
                            <td>
                              <div class="px-2 py-1 d-flex">
                                <div class="icon icon-shape bg-gradient-primary shadow me-2">
                                  <i class="fab fa-facebook-f"></i>
                                </div>
                                <div class="d-flex flex-column justify-content-center">
                                  <h6 class="mb-0 text-sm">Facebook Reach</h6>
                                </div>
                              </div>
                            </td>
                            <td class="text-sm text-center align-middle">
                              <span class="text-xs font-weight-bold">
                                {{ calculatePercentage(facebookData.reach) }}%
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="px-2 py-1 d-flex">
                                <div class="icon icon-shape bg-gradient-danger shadow me-2">
                                  <i class="fab fa-instagram"></i>
                                </div>
                                <div class="d-flex flex-column justify-content-center">
                                  <h6 class="mb-0 text-sm">Instagram Reach</h6>
                                </div>
                              </div>
                            </td>
                            <td class="text-sm text-center align-middle">
                              <span class="text-xs font-weight-bold">
                                {{ calculatePercentage(instagramData.reach) }}%
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import Chart from 'chart.js/auto';

const isOpen = ref(false);
const facebookData = ref({ postImpressionsUnique: 0, reach: 0 });
const instagramData = ref({ postImpressionsUnique: 0, reach: 0 });
const doughnutChart = ref(null);
let chart = null;

const calculatePercentage = (value) => {
  const total = facebookData.value.reach + instagramData.value.reach;
  return total ? Math.round((value / total) * 100) : 0;
};

const updateChart = () => {
  if (chart) {
    chart.destroy();
  }

  const ctx = doughnutChart.value.getContext('2d');
  chart = new Chart(ctx, {
    type: 'doughnut',
    data: {
      labels: ['Facebook Reach', 'Instagram Reach'],
      datasets: [{
        data: [facebookData.value.reach, instagramData.value.reach],
        backgroundColor: ['#007bff', '#dc3545'],
        borderWidth: 0
      }]
    },
    options: {
      responsive: true,
      cutout: '70%',
      plugins: {
        legend: {
          display: false
        }
      }
    }
  });
};

watch([facebookData, instagramData], () => {
  if (doughnutChart.value) {
    updateChart();
  }
});

const openModal = (fbData, igData) => {
  facebookData.value = fbData;
  instagramData.value = igData;
  isOpen.value = true;
  setTimeout(updateChart, 100);
};

const closeModal = () => {
  isOpen.value = false;
};

defineExpose({
  openModal,
  closeModal
});
</script>

<style scoped>
.modal-content {
  background: #2e2f33 !important;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1) !important;
  border: none !important;
}

.table {
  background: #242025 !important;
}

.thead-light th {
  background: #202125 !important;
  color: #888c9f !important;
  font-weight: 600 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.table td {
  background: #202125 !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
}

.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.5) !important;
}

.btn-close {
  background-color: #202125 !important;
  opacity: 1 !important;
  padding: 0.75rem !important;
  border-radius: 8px !important;
  transition: all 0.2s ease !important;
}

.btn-close:hover {
  background-color: #dc3545 !important;
  transform: rotate(90deg);
}

.icon-shape {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
}

.bg-gradient-primary {
  background: linear-gradient(310deg, #007bff, #0056b3);
}

.bg-gradient-danger {
  background: linear-gradient(310deg, #dc3545, #a71d2a);
}

.bg-gradient-success {
  background: linear-gradient(310deg, #28a745, #1e7e34);
}

.bg-gradient-warning {
  background: linear-gradient(310deg, #ffc107, #d39e00);
}
</style>