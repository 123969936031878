import { ref } from 'vue';
import { 
  getAuth, 
  sendPasswordResetEmail, 
  verifyBeforeUpdateEmail, 
  sendEmailVerification, 
  createUserWithEmailAndPassword 
} from 'firebase/auth';

export function usePasswordManagement() {
  const auth = getAuth();
  const resetEmail = ref('');
  const newEmail = ref('');
  const errorMessage = ref('');
  const successMessage = ref('');
  const isLoading = ref(false);

  const handleAuthError = (error, customMessages = {}) => {
    console.error('Auth fejl:', error);
    const errorMessages = {
      'auth/user-not-found': 'Der findes ingen bruger med denne e-mailadresse',
      'auth/invalid-email': 'Ugyldig e-mailadresse format',
      'auth/too-many-requests': 'For mange forsøg. Prøv igen senere',
      'auth/requires-recent-login': 'For din sikkerhed skal du logge ind igen før du kan fortsætte',
      'auth/email-already-in-use': 'Denne e-mailadresse er allerede i brug',
      ...customMessages
    };
    errorMessage.value = errorMessages[error.code] || `Der opstod en fejl: ${error.message}`;
  };

  const resetState = () => {
    errorMessage.value = '';
    successMessage.value = '';
    isLoading.value = true;
  };

  const sendVerificationAndResetEmails = async (email) => {
    if (!email) {
      errorMessage.value = 'Indtast venligst en e-mailadresse';
      return;
    }

    resetState();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, 'temporaryPassword');
      const user = userCredential.user;

      await Promise.all([
        sendEmailVerification(user, {
          url: `${window.location.origin}/profile`
        }),
        sendPasswordResetEmail(auth, email, {
          url: `${window.location.origin}/login`
        })
      ]);

      successMessage.value = 'Verifikations- og nulstillingsmails er blevet sendt';
    } catch (error) {
      handleAuthError(error);
    } finally {
      isLoading.value = false;
    }
  };

  const resetPassword = async () => {
    if (!resetEmail.value) {
      errorMessage.value = 'Indtast venligst en e-mailadresse';
      return;
    }

    resetState();

    try {
      await sendPasswordResetEmail(auth, resetEmail.value, {
        url: `${window.location.origin}/login`,
        handleCodeInApp: true
      });
      successMessage.value = 'Nulstillingslink er sendt til din e-mail. Tjek også din spam-mappe.';
    } catch (error) {
      handleAuthError(error);
    } finally {
      isLoading.value = false;
    }
  };

  const updateEmail = async () => {
    if (!newEmail.value) {
      errorMessage.value = 'Indtast venligst en ny e-mailadresse';
      return;
    }

    resetState();

    try {
      const user = auth.currentUser;
      if (!user) throw new Error('Ingen bruger er logget ind');

      await verifyBeforeUpdateEmail(user, newEmail.value, {
        url: `${window.location.origin}/profile`
      });
      
      successMessage.value = 'En bekræftelsesmail er sendt til din nuværende email-adresse. ' +
                           'Bekræft ændringen ved at klikke på linket i mailen.';
      newEmail.value = '';
    } catch (error) {
      handleAuthError(error);
    } finally {
      isLoading.value = false;
    }
  };

  const loadUserEmail = () => {
    const user = auth.currentUser;
    if (user?.email) {
      resetEmail.value = user.email;
    }
  };

  const sendPasswordResetForUser = async (userEmail) => {
    if (!userEmail) {
      throw new Error('Ingen email angivet');
    }

    try {
      const actionCodeSettings = {
        url: `${window.location.origin}/login`,
        handleCodeInApp: true
      };

      await sendPasswordResetEmail(auth, userEmail, actionCodeSettings);
      return 'Nulstillingslink er sendt til brugerens email';
    } catch (error) {
      console.error('Fejl ved afsendelse af nulstillingsmail:', error);
      throw error;
    }
  };

  return {
    resetEmail,
    newEmail,
    errorMessage,
    successMessage,
    isLoading,
    resetPassword,
    updateEmail,
    sendVerificationAndResetEmails,
    loadUserEmail,
    sendPasswordResetForUser
  };
}