<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { signOut } from "firebase/auth";
import { auth } from "@/firebase"; // Ensure Firebase auth is set up correctly
import { activateDarkMode } from "@/assets/js/dark-mode"; // Only activate dark mode
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";

const store = useStore();
const router = useRouter();

// Remove the assignment statement
computed(() => store.state.isNavFixed);
computed(() => store.state.sidebarType);
const toggleConfigurator = () => store.commit("toggleConfigurator");

// mutations
const setSidebarType = (type) => store.commit("sidebarType", type);

// Force dark mode to always be activated
store.state.darkMode = true;
activateDarkMode();
setSidebarType("bg-default");

// Session flag til at undgå unødvendige opdateringer ved refresh
const isInitialMount = ref(true);

// Funktion til at opdatere online status
const updateOnlineStatus = async (uid, status) => {
  if (!uid) return;
  try {
    const userDocRef = doc(db, "users", uid);
    await updateDoc(userDocRef, { isOnline: status });
  } catch (error) {
    console.error("Error updating online status:", error);
  }
};

// Håndter browser lukning/refresh
const handleTabClose = async (event) => {
  const currentUser = auth.currentUser;
  if (!currentUser) return;

  // Gem en timestamp i sessionStorage
  sessionStorage.setItem('lastActivity', Date.now().toString());
  await updateOnlineStatus(currentUser.uid, false);
};

// Setup event listeners
onMounted(() => {
  const lastActivity = sessionStorage.getItem('lastActivity');
  const currentTime = Date.now();
  
  // Lyt efter auth ændringer
  const unsubscribe = auth.onAuthStateChanged(async (user) => {
    if (user) {
      // Hvis dette er første mount eller der er gået mere end 3 sekunder
      if (isInitialMount.value || !lastActivity || (currentTime - parseInt(lastActivity)) > 3000) {
        await updateOnlineStatus(user.uid, true);
      }
      isInitialMount.value = false;
    }
  });

  // Tilføj event listeners
  window.addEventListener('beforeunload', handleTabClose);
  
  document.addEventListener('visibilitychange', async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    if (document.visibilityState === 'hidden') {
      await updateOnlineStatus(currentUser.uid, false);
    } else if (document.visibilityState === 'visible') {
      // Kun opdater hvis siden har været skjult i mere end 3 sekunder
      const lastActivity = sessionStorage.getItem('lastActivity');
      if (lastActivity && (Date.now() - parseInt(lastActivity)) > 3000) {
        await updateOnlineStatus(currentUser.uid, true);
      }
    }
  });

  // Cleanup når komponenten unmountes
  onUnmounted(() => {
    unsubscribe();
    window.removeEventListener('beforeunload', handleTabClose);
  });
});

// Opdater den eksisterende logOut funktion
const logOut = async () => {
  try {
    const currentUser = auth.currentUser;
    if (currentUser) {
      sessionStorage.removeItem('lastActivity'); // Ryd session data ved logout
      await updateOnlineStatus(currentUser.uid, false);
    }
    await signOut(auth);
    router.push("/authentication/signin");
  } catch (error) {
    console.error("Logout error: ", error);
  }
};
</script>

<template>
  <div class="fixed-plugin">
    <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed"
      @click="toggleConfigurator"
    >
      <i class="py-2 fa fa-cog"></i>
    </a>
    <div class="shadow-lg card">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="" :class="isRTL ? 'float-end' : 'float-start'">
          <h5 class="mt-3 mb-0">MakeMore</h5>
          <p>Indstillinger</p>
        </div>
        <div
          class="mt-4"
          :class="isRTL ? 'float-start' : 'float-end'"
          @click="toggleConfigurator"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <!-- Sidebar Backgrounds -->
        <a href="#" class="switch-trigger background-color"> </a>
        <p class="mt-2 text-sm d-xl-none d-block">
          You can change the sidenav type just on desktop view.
        </p>

        <hr class="horizontal dark my-4" />

        <!-- Logout Button -->
        <div class="mt-4">
          <button class="btn btn-danger w-100" @click="logOut">
            Leave for now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
