<script setup>
import { createApp } from "vue";
import { ref, onMounted, onBeforeUnmount, computed, onUnmounted, watch } from "vue";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Swal from "sweetalert2";
import EventDetailsModal from "./components/EventDetailsModal.vue";
import daLocale from '@fullcalendar/core/locales/da';



import {
  getFirestore,
  collection,
  query,
  getDocs,
  doc,
  updateDoc,
  onSnapshot,
  where,
  getDoc,
  enableIndexedDbPersistence,
} from "firebase/firestore";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBolt, faImage, faVideo, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram as faInstagramBrand,
} from "@fortawesome/free-brands-svg-icons";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useUserContext } from '@/composables/useUserContext'
import { useStore } from 'vuex'
import useCurrentProfile from '@/composables/useCurrentProfile'
import { useRoute } from 'vue-router'
import GetLinks from '@/components/getLinks.vue'
import axios from 'axios';
import SocialMediaAnalytics from '@/components/SocialMediaAnalytics.vue';

// Tilføj FontAwesome ikoner til biblioteket
library.add(faFacebookF, faInstagramBrand, faBolt, faImage, faVideo, faCheckCircle);

// Farver til ikoner
const iconColors = ref({
  facebook: "#007fff",
  instagram: "#ff0047",
  lightning: "#f39c12",
  image: "#ffffff",
  video: "#ffffff",
});

const calendarEl = ref(null);
let calendar;

const auth = getAuth();
const currentUser = ref(null);

onAuthStateChanged(auth, (user) => {
  currentUser.value = user;
});

// Opdater isSuperAdmin funktionen til at inkludere role 2
const isSuperAdmin = async (user) => {
  if (!user) return false;
  
  const db = getFirestore();
  try {
    const userDoc = await getDoc(doc(db, "users", user.uid));
    const userData = userDoc.data();
    return userData && (userData.role === 1 || userData.role === 2); // Tilføjet role 2
  } catch (error) {
    console.error("Fejl ved tjek af superAdmin status:", error);
    return false;
  }
};

// Funktion til at hente posts
const fetchPosts = async () => {
  if (!currentUser.value) return [];

  const db = getFirestore();
  let postsQuery;
  
  // Hent brugerens rolle
  const userDoc = await getDoc(doc(db, "users", currentUser.value.uid));
  const userRole = userDoc.data()?.role;
  
  // Hvis bruger er rolle 3, hent kun deres egne posts
  if (userRole === 3) {
    postsQuery = query(collection(db, "posts"), 
      where("userId", "==", currentUser.value.uid));
  } else {
    // For admin og superadmin (rolle 1 og 2)
    postsQuery = query(collection(db, "posts"), 
      where("id", "==", route.params.id || store.state.activeUser?.id));
  }

  const querySnapshot = await getDocs(postsQuery);
  const events = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    
    // For rolle 3, tjek kun deres egne posts
    if (userRole === 3 ? data.userId === currentUser.value.uid : 
        (isSuperAdmin(currentUser.value) || data.id === currentUser.value?.uid)) {
      const platform = data.postFB
        ? "Facebook"
        : data.postInst
          ? "Instagram"
          : null;
      const icon =
        platform === "Facebook"
          ? "facebook"
          : platform === "Instagram"
            ? "instagram"
            : null;

      let eventClass = null;

      if (platform === "Facebook") {
        eventClass = "bg-gradient-facebook";
      } else if (platform === "Instagram") {
        eventClass = "bg-gradient-instagram";
      }

      const childrenAddresses = data.children
        ? data.children.map((child) => child.adressen)
        : [];
      const mediaFiles = data.children?.[0]?.images || [];

      // Håndter publishTime korrekt
      let time = "00:00";

      if (data.publishTime) {
        if (
          typeof data.publishTime === "object" &&
          data.publishTime.HH &&
          data.publishTime.MM
        ) {
          const hours = data.publishTime.HH.toString().padStart(2, "0");
          const minutes = data.publishTime.MM.toString().padStart(2, "0");
          time = `${hours}:${minutes}`;
        } else if (typeof data.publishTime === "string") {
          // Hvis publishTime er en streng, antag formatet "HH:mm"
          time = data.publishTime;
        } else if (data.publishTime instanceof Date) {
          const hours = data.publishTime.getHours().toString().padStart(2, "0");
          const minutes = data.publishTime
            .getMinutes()
            .toString()
            .padStart(2, "0");
          time = `${hours}:${minutes}`;
        } else if (typeof data.publishTime === "number") {
          // Hvis publishTime er et timestamp
          const date = new Date(data.publishTime);
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          time = `${hours}:${minutes}`;
        } else {
          console.warn("Uventet format for publishTime:", data.publishTime);
          // Behold default start og time
        }
      }

      events.push({
        id: doc.id,
        title: data.subject || "No Title",
        start: data.publishDate,
        extendedProps: {
          platform,
          time, // Sæt tid korrekt
          icon,
          addresses: childrenAddresses,
          mediaFiles,
          children: data.children || [],
          status: "Planlagt",
          text: data.text || "No description available",
          collection: "posts",
          data: data
        },
        className: eventClass,
      });
    }
  });

  return events;
};

// Funktion til at hente postSend
const fetchPostSend = async () => {
  if (!currentUser.value) return [];

  const db = getFirestore();
  let postSendQuery;
  
  // Hent brugerens rolle
  const userDoc = await getDoc(doc(db, "users", currentUser.value.uid));
  const userRole = userDoc.data()?.role;
  
  // Hvis bruger er rolle 3, hent kun deres egne posts
  if (userRole === 3) {
    postSendQuery = query(collection(db, "postSend"), 
      where("userId", "==", currentUser.value.uid));
  } else {
    // For admin og superadmin (rolle 1 og 2)
    postSendQuery = query(collection(db, "postSend"), 
      where("id", "==", route.params.id || store.state.activeUser?.id));
  }

  const querySnapshot = await getDocs(postSendQuery);
  const events = [];

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    
    // For rolle 3, tjek kun deres egne posts
    if (userRole === 3 ? data.userId === currentUser.value.uid : 
        (isSuperAdmin(currentUser.value) || data.id === currentUser.value?.uid)) {
      const platform = data.postFB
        ? "Facebook"
        : data.postInst
          ? "Instagram"
          : null;
      const icon =
        platform === "Facebook"
          ? "facebook"
          : platform === "Instagram"
            ? "instagram"
            : null;

      let eventClass = null;

      if (platform === "Facebook") {
        eventClass = "bg-gradient-facebook";
      } else if (platform === "Instagram") {
        eventClass = "bg-gradient-instagram";
      }

      const childrenAddresses = data.children
        ? data.children.map((child) => child.adressen)
        : [];
      const mediaFiles = data.children?.[0]?.images || [];

      let time = "00:00";

      if (data.publishTime) {
        if (
          typeof data.publishTime === "object" &&
          data.publishTime.HH &&
          data.publishTime.MM
        ) {
          const hours = data.publishTime.HH.toString().padStart(2, "0");
          const minutes = data.publishTime.MM.toString().padStart(2, "0");
          time = `${hours}:${minutes}`;
        } else if (typeof data.publishTime === "string") {
          // Hvis publishTime er en streng, antag formatet "HH:mm"
          time = data.publishTime;
        } else if (data.publishTime instanceof Date) {
          const hours = data.publishTime.getHours().toString().padStart(2, "0");
          const minutes = data.publishTime
            .getMinutes()
            .toString()
            .padStart(2, "0");
          time = `${hours}:${minutes}`;
        } else if (typeof data.publishTime === "number") {
          // Hvis publishTime er et timestamp
          const date = new Date(data.publishTime);
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          time = `${hours}:${minutes}`;
        } else {
          console.warn("Uventet format for publishTime:", data.publishTime);
          // Behold default start og time
        }
      }

      events.push({
        id: doc.id,
        title: data.subject || "No Title",
        start: data.publishDate,
        extendedProps: {
          platform,
          time, // Sæt tid korrekt
          icon,
          addresses: childrenAddresses,
          mediaFiles,
          children: data.children || [],
          status: "Opslået",
          text: data.text || "No description available",
          collection: "postSend", // Tilføj denne linje
        },
        className: eventClass,
      });
    }
  });

  return events;
};

// Funktion til at generere dynamiske ikoner
const getDynamicIcons = (children, status, data) => {
  // Tjek om det er et automatisk opslag baseret på collectionName
  let iconsHtml = status === "Opslået" 
    ? `<i class="fas fa-check-circle" style="font-size: 14px; color: #5dc87a; margin-right: 5px;"></i>`
    : data?.collectionName 
      ? `<i class="fas fa-bolt" style="font-size: 14px; color: ${iconColors.value.lightning}; margin-right: 5px;"></i>`
      : '';  // Intet lyn-ikon hvis der ikke er collectionName

  let hasImage = false;
  let hasVideo = false;

  if (children && children.length > 0) {
    children.forEach((child) => {
      if (child.images && child.images.length > 0) {
        child.images.forEach((file) => {
          if (
            !hasImage &&
            (file.includes(".jpg") ||
              file.includes(".jpeg") ||
              file.includes(".png"))
          ) {
            const imageIcon = data?.type === 'karruselPost' 
              ? `<i class="fas fa-images" style="font-size: 14px; color: ${iconColors.value.image}; margin-right: 0px;"></i>`
              : `<i class="fas fa-image" style="font-size: 14px; color: ${iconColors.value.image}; margin-right: 0px;"></i>`;
            iconsHtml += ` ${imageIcon}`;
            hasImage = true;
          } else if (
            !hasVideo &&
            (file.includes(".mp4") || file.includes(".mov"))
          ) {
            iconsHtml += ` <i class="fas fa-video" style="font-size: 14px; color: ${iconColors.value.video}; margin-right: 0px;"></i>`;
            hasVideo = true;
          }
        });
      }
    });
  }

  return iconsHtml;
};

// Funktion til at opdatere event i Firestore
const updateEventInFirestore = async (eventId, newDate, collectionName) => {
  const db = getFirestore();
  const eventRef = doc(db, collectionName, eventId);

  // Konstruer datoen baseret på lokale komponenter
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");
  const updatedPublishDate = `${year}-${month}-${day}`;

  try {
    await updateDoc(eventRef, { publishDate: updatedPublishDate });
    console.log(
      `Event ${eventId} updated in ${collectionName} with date ${updatedPublishDate}`,
    );
  } catch (error) {
    console.error(
      `Error updating event ${eventId} in ${collectionName}: `,
      error,
    );
    Swal.fire({
      icon: "error",
      title: "Fejl",
      text: "Der opstod en fejl under opdatering af eventet.",
    });
  }
};

// Funktion til at bestemme post type baseret på mediefiler
const getPostTypeFromMediaFiles = (children) => {
  let postType = "";

  if (children && children.length > 0) {
    children.forEach((child) => {
      if (child.images && child.images.length > 0) {
        child.images.forEach((file) => {
          if (
            file.includes(".jpg") ||
            file.includes(".jpeg") ||
            file.includes(".png")
          ) {
            postType = "Billedeopslag";
          } else if (file.includes(".mp4") || file.includes(".mov")) {
            postType = "Videoopslag";
          }
        });
      }
    });
  }

  return postType || "Ingen medieopslag";
};

// Funktion til at vise event detaljer i SweetAlert2 modal
const showEventDetails = (event) => {
  const children = event.extendedProps.children || [];
  const mediaFiles = children.flatMap((child) => child.images);
  const addresses = children.map((child) => child.adressen);
  const description = event.extendedProps.text || "No description available";
  const eventId = event.id;

  Swal.fire({
    title: event.title || "Event Details",
    html: '<div id="event-details-container"></div>',
    showCloseButton: true,
    width: "50%",
    padding: "1rem",
    showConfirmButton: false,
    backdrop: true,
    customClass: {
      popup: "custom-popup-class",
    },
    didOpen: () => {
      const container = document.getElementById("event-details-container");
      const modalApp = createApp(EventDetailsModal, {
        mediaFiles,
        addresses,
        description,
        eventId,
        collection: event.extendedProps.collection,
        eventData: event.extendedProps.data
      });
      modalApp.mount(container);

      // Gem modalApp for at kunne unmount den senere
      Swal.getPopup().modalApp = modalApp;
    },
    willClose: () => {
      // Unmount Vue app når modal lukkes
      if (Swal.getPopup().modalApp) {
        Swal.getPopup().modalApp.unmount();
      }
    },
  });
};

// Funktion til at håndtere "Vis mere" klik
const handleShowMore = (event) => {
  event.preventDefault();
  event.stopPropagation(); // Stopper eventet fra at propagere til eventClick

  const showMoreLink = event.currentTarget;
  const childrenContainer = showMoreLink.closest(".children-container");
  const hiddenChildren = childrenContainer.querySelectorAll(".hidden-child");
  const showLessLink = childrenContainer.querySelector(".show-less-link");

  hiddenChildren.forEach((child) => {
    child.style.display = "block";
  });

  showMoreLink.style.display = "none";
  if (showLessLink) {
    showLessLink.style.display = "block";
  }
};

// Funktion til at håndtere "Vis mindre" klik
const handleShowLess = (event) => {
  event.preventDefault();
  event.stopPropagation(); // Stopper eventet fra at propagere til eventClick

  const showLessLink = event.currentTarget;
  const childrenContainer = showLessLink.closest(".children-container");
  const hiddenChildren = childrenContainer.querySelectorAll(".hidden-child");
  const showMoreLink = childrenContainer.querySelector(".show-more-link");

  hiddenChildren.forEach((child) => {
    child.style.display = "none";
  });

  showLessLink.style.display = "none";
  if (showMoreLink) {
    showMoreLink.style.display = "block";
  }
};

// Definér analytics ref
const analytics = ref({
  totalPosts: 0,
  totalImages: 0,
  totalVideos: 0,
  totalReach: 0,
  totalClicks: 0,
  totalAnimations: 0,
  facebookPosts: 0,
  instagramPosts: 0,
  scheduledPosts: 0,
  publishedPosts: 0
});

// Funktion til at tlle posts og media
const countPostsAndMedia = async () => {
  if (!currentUser.value) return;
  
  const db = getFirestore();
  
  try {
    const userDoc = await getDoc(doc(db, "users", currentUser.value.uid));
    const userRole = userDoc.data()?.role;
    
    let postsQuery;
    let postSendQuery;
    
    if (userRole === 3) {
      // Brug 'id' i stedet for 'userId' for at matche datastrukturen
      postsQuery = query(
        collection(db, "posts"),
        where("id", "==", currentUser.value.uid)  // Ændret fra userId til id
      );
      postSendQuery = query(
        collection(db, "postSend"),
        where("id", "==", currentUser.value.uid)  // Ændret fra userId til id
      );
    } else {
      const userId = route.params.id || store.state.activeUser?.id;
      postsQuery = query(
        collection(db, "posts"),
        where("id", "==", userId)
      );
      postSendQuery = query(
        collection(db, "postSend"),
        where("id", "==", userId)
      );
    }

    const [postsSnapshot, postSendSnapshot] = await Promise.all([
      getDocs(postsQuery),
      getDocs(postSendQuery)
    ]);

    let totalPosts = 0;
    let totalImages = 0;
    let totalAnimations = 0;

    const videoExtensions = [".mp4", ".mov", ".avi", ".mkv"];

    // Tæl fra posts
    postsSnapshot.forEach(doc => {
      const postData = doc.data();
      totalPosts++;
      
      // Kun tæl fra children.images
      if (postData.children && Array.isArray(postData.children)) {
        postData.children.forEach(child => {
          if (child.images && Array.isArray(child.images)) {
            child.images.forEach(url => {
              const urlLower = url.toLowerCase();
              if (videoExtensions.some(ext => urlLower.endsWith(ext))) {
                totalAnimations++;
              } else {
                totalImages++;
              }
            });
          }
        });
      }
    });

    // Tæl fra postSend
    postSendSnapshot.forEach(doc => {
      const postData = doc.data();
      totalPosts++;
      
      // Kun tæl fra children.images
      if (postData.children && Array.isArray(postData.children)) {
        postData.children.forEach(child => {
          if (child.images && Array.isArray(child.images)) {
            child.images.forEach(url => {
              const urlLower = url.toLowerCase();
              if (videoExtensions.some(ext => urlLower.endsWith(ext))) {
                totalAnimations++;
              } else {
                totalImages++;
              }
            });
          }
        });
      }
    });

    // Opdater analytics
    analytics.value = {
      ...analytics.value,
      totalPosts,
      totalImages,
      totalAnimations,
      totalReach: analytics.value.totalReach,
      totalClicks: analytics.value.totalClicks
    };
    
  } catch (error) {
    console.error("Fejl i countPostsAndMedia:", error);
    console.error("Fejlkode:", error.code);
    console.error("Fejlbesked:", error.message);
  }
};

// Opdater setupAnalyticsListener funktionen
const setupAnalyticsListener = async () => {
  if (!currentUser.value) return;
  
  const db = getFirestore();
  
  try {
    // Hent brugerens rolle først
    const userDoc = await getDoc(doc(db, "users", currentUser.value.uid));
    const userRole = userDoc.data()?.role;
    
    let analyticsRef;
    let metaAnalyticsRef;
    
    if (userRole === 3) {
      // For rolle 3 - kun egne analytics
      analyticsRef = doc(db, "users", currentUser.value.uid, "analytics", "stats");
      metaAnalyticsRef = doc(db, "users", currentUser.value.uid, "analytics", "meta");
    } else {
      // For admin og superadmin (rolle 1 og 2)
      const userId = route.params.id || store.state.activeUser?.id;
      analyticsRef = doc(db, "users", userId, "analytics", "stats");
      metaAnalyticsRef = doc(db, "users", userId, "analytics", "meta");
    }

    // Lyt til ændringer i analytics dokumentet
    const unsubscribe = onSnapshot(analyticsRef, {
      next: (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          analytics.value = {
            ...analytics.value,
            totalClicks: data.totalClicks || 0,
            totalPosts: analytics.value.totalPosts,
            totalImages: analytics.value.totalImages,
            totalVideos: analytics.value.totalVideos,
            totalAnimations: analytics.value.totalAnimations,
            facebookPosts: analytics.value.facebookPosts,
            instagramPosts: analytics.value.instagramPosts,
            scheduledPosts: analytics.value.scheduledPosts,
            publishedPosts: analytics.value.publishedPosts
          };
        }
      },
      error: (error) => {
        console.error('Fejl i analytics listener:', error);
      }
    });

    // Tilføj listener for Meta analytics
    const unsubscribeMeta = onSnapshot(metaAnalyticsRef, {
      next: (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const fbReach = data.facebook?.post_impressions_unique || 0;
          const igReach = data.instagram?.reach || 0;
          
          // Opdater total reach i analytics
          analytics.value = {
            ...analytics.value,
            totalReach: fbReach + igReach
          };
        }
      },
      error: (error) => {
        console.error('Fejl i meta analytics listener:', error);
      }
    });

    // Cleanup ved component unmount
    onUnmounted(() => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
      if (typeof unsubscribeMeta === 'function') {
        unsubscribeMeta();
      }
    });
    
  } catch (error) {
    console.error('Fejl ved setup af analytics listener:', error);
  }
};

// Initialize route, store and user context at the top
const route = useRoute()
const store = useStore()
const { contextUserId, canAccessOtherUsers } = useUserContext()
const { profileUserId } = useCurrentProfile()

// Tilføj denne funktion i starten af filen, efter imports
const suppressFirestoreLogging = () => {
  // Deaktiver webchannel logging
  window.addEventListener('load', () => {
    console.debug = () => {};
    const originalLog = console.log;
    console.log = (...args) => {
      if (args[0]?.includes?.('Fetch finished loading: GET "https://firestore.googleapis.com/google.firestore.v1.Firestore/Listen')) {
        return;
      }
      originalLog.apply(console, args);
    };
  });
};

// Kald funktionen tidligt i koden
suppressFirestoreLogging();

// Funktion til at initialisere kalenderen
const initializeCalendar = async () => {
  try {
    const [posts, postSend] = await Promise.all([
      fetchPosts(),
      fetchPostSend()
    ]);
    
    const events = [...posts, ...postSend];
    
    // Kald countPostsAndMedia før kalenderen initialiseres
    await countPostsAndMedia();

    if (calendarEl.value) {
      calendar = new Calendar(calendarEl.value, {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        editable: true,
        eventStartEditable: true,
        eventDurationEditable: false, // Deaktiver resize
        dragRevertDuration: 200, // Hurtigere revert animation
        eventDragMinDistance: 10, // Kræv lidt mere bevægelse før drag starter
        dragScroll: true,
        snapDuration: { minutes: 30 }, // Snap til hver halve time
        eventDragStart: (info) => {
          info.el.style.zIndex = '1000';
          info.el.style.opacity = '0.8';
          info.el.style.transform = 'scale(1.02)';
        },
        eventDragStop: (info) => {
          info.el.style.zIndex = '';
          info.el.style.opacity = '';
          info.el.style.transform = '';
        },
        events,
        locale: daLocale,
        firstDay: 1, // 1 for mandag
        weekNumbers: true,
        weekText: "",
        dayHeaderFormat: { weekday: 'long' }, // Tilføj denne linje for at få fulde dagnavne
        eventClick: (info) => {
          showEventDetails(info.event);
        },
        dayCellDidMount: (arg) => {
          arg.el.style.padding = '0';
        },
        eventDidMount: (info) => {
          info.el.style.marginBottom = '3px';
          const showMoreLink = info.el.querySelector(".show-more-link");
          const showLessLink = info.el.querySelector(".show-less-link");
          const hiddenChildren = info.el.querySelectorAll(".child-element[style*='display: none']");

          if (showMoreLink) {
            showMoreLink.addEventListener("click", (e) => {
              e.preventDefault();
              e.stopPropagation();
              hiddenChildren.forEach(child => child.style.display = "block");
              showMoreLink.style.display = "none";
              showLessLink.style.display = "block";
            });
          }

          if (showLessLink) {
            showLessLink.addEventListener("click", (e) => {
              e.preventDefault();
              e.stopPropagation();
              hiddenChildren.forEach(child => child.style.display = "none");
              showMoreLink.style.display = "block";
              showLessLink.style.display = "none";
            });
          }
        },
        eventContent: (info) => {
          const { time, icon, children, status } = info.event.extendedProps;
          const eventData = info.event.extendedProps.data || {};
          const iconClass = icon === 'facebook' ? 'fab fa-facebook' : 'fab fa-instagram';
          const hasVideo = eventData.children?.some(child => 
            child.images?.some(image => 
              image.toLowerCase().endsWith('.mp4') || 
              image.toLowerCase().endsWith('.mov')
            )
          );
          let eventTitle = `${info.event.title}${
            eventData.type === 'karruselPost' 
              ? ' | <strong>Karrusel</strong>' 
              : hasVideo 
                ? ' | <strong>Video</strong>' 
                : ''
          }`;
          
          // Generer HTML for alle headlines
          const headlinesHTML = children?.map((child, index) => `
            <div class="child-element ${index > 0 ? 'hidden-child' : ''}" style="display: ${index > 0 ? 'none' : 'block'}">
              <div style="font-weight: bold; font-size: 11px; margin-top: 2px;">${child.headline || 'Ingen overskrift'}</div>
            </div>
          `).join('') || '';

          // Tilføj "vis mere/mindre" links hvis der er mere end ét barn
          const showMoreLessHTML = children?.length > 1 ? `
            <div style="text-align: left; margin-top: 2px;">
              <a class="show-more-link" style="display: block; font-size: 10px; color: #c5c5c5; cursor: pointer;">
                Vis ${children.length - 1} mere...
              </a>
              <a class="show-less-link" style="display: none; font-size: 10px; color: #c5c5c5; cursor: pointer;">
                Vis mindre
              </a>
            </div>
          ` : '';

          const dynamicIcons = getDynamicIcons(children, status, eventData);

          return {
            html: `
              <div class="fc-event-main" style="padding: 3px; font-size: 12px;">
                <div class="event-inner-container" style="border: 1px solid rgba(0,0,0,0.0); border-radius: 3px; padding: 5px; position: relative;">
                  <div class="fc-title" style="font-size: 12px;">${eventTitle}</div>
                  <i class="${iconClass} fa-md" aria-hidden="true" style="position: absolute; top: 5px; right: 5px; font-size: 16px;"></i>
                  <div style="position: absolute; bottom: 5px; right: 5px; font-size: 14px;">${dynamicIcons}</div>
                  <div class="children-container">
                    ${headlinesHTML}
                    ${showMoreLessHTML}
                  </div>
                  <div class="fc-post-text-row" style="font-size: 10px;">
                    <span class="fc-post-text" style="color: ${status === 'Opslået' ? 'rgb(93, 200, 122)' : '#f3d912'}; font-weight: bold;">${status}</span>
                    <span class="fc-datetime-text" style="margin-left: 5px; color: #c5c5c5; font-weight: bold;">kl. ${time || "00:00"}</span>
                  </div>
                </div>
              </div>
            `
          };
        },
        eventDrop: (info) => {
          const eventId = info.event.id;
          const newDate = info.event.start;
          const collectionName = info.event.extendedProps.collection;

          updateEventInFirestore(eventId, newDate, collectionName);
        },
        height: '100%',
      });

      calendar.render();
      
      // Setup real-time listeners efter kalenderen er renderet
      await setupRealTimeListeners();
    }

    // Kør updateAnalytics asynkront
    updateAnalytics().catch(error => {
      console.error('Fejl ved opdatering af analytics:', error);
    });

  } catch (error) {
    console.error("Error initializing calendar: ", error);
    Swal.fire({
      icon: "error",
      title: "Fejl",
      text: "Der opstod en fejl under indlæsning af kalenderen.",
    });
  }
};

onMounted(() => {
  onAuthStateChanged(auth, (user) => {
    currentUser.value = user;
    if (user) {
      initializeCalendar();
      setupAnalyticsListener();
      countPostsAndMedia(); // Initial optælling
    }
  });
});

// Ødelæg kalenderen ved unmount
onBeforeUnmount(() => {
  if (calendar) {
    calendar.destroy();
  }
});

document.addEventListener('click', function(event) {
  if (event.target.matches('.nav-pill')) {
    // Håndter klik på nav-pill her
  }
});

// Funktion til at vise udvidet analytics
const showAnalytics = () => {
  const fbData = {
    postImpressionsUnique: analytics.value.facebookPosts,
    reach: analytics.value.totalReach // eller en anden relevant værdi
  };
  const igData = {
    postImpressionsUnique: analytics.value.instagramPosts,
    reach: analytics.value.totalReach // eller en anden relevant værdi
  };
  analyticsModalRef.value?.openModal(fbData, igData);
};

// Opdater setupRealTimeListeners funktionen
const setupRealTimeListeners = async () => {
  if (!currentUser.value) return;
  
  const db = getFirestore();
  const userId = route.params.id || store.state.activeUser?.id;

  if (!userId) {
    logger.error('Ingen bruger ID fundet');
    return;
  }

  try {
    const userDoc = await getDoc(doc(db, "users", currentUser.value.uid));
    if (!userDoc.exists()) {
      logger.error('Bruger dokument findes ikke');
      return;
    }

    const postsQuery = query(collection(db, "posts"), 
      where("id", "==", userId));
    const postSendQuery = query(collection(db, "postSend"), 
      where("id", "==", userId));

    // Lyt til ændringer med error handling
    const unsubscribe = onSnapshot(postsQuery, {
      next: (snapshot) => {
        if (currentUser.value) {
          snapshot.docChanges().forEach((change) => {
            handleDocumentChange(change, "posts");
          });
          countPostsAndMedia();
        }
      },
      error: (error) => {
        if (error.code !== 'permission-denied') {
          logger.error('Fejl i posts listener:', error);
        }
      }
    });

    const unsubscribeSend = onSnapshot(postSendQuery, {
      next: (snapshot) => {
        if (currentUser.value) {
          snapshot.docChanges().forEach((change) => {
            handleDocumentChange(change, "postSend");
          });
          countPostsAndMedia();
        }
      },
      error: (error) => {
        if (error.code !== 'permission-denied') {
          logger.error('Fejl i postSend listener:', error);
        }
      }
    });

    // Cleanup ved unmount eller logout
    onUnmounted(() => {
      unsubscribe();
      unsubscribeSend();
    });

  } catch (error) {
    logger.error('Fejl i setupRealTimeListeners:', error);
  }
};

const handleDocumentChange = (change, collectionName) => {
  if (!calendar) return;

  const docData = change.doc.data();
  const eventId = change.doc.id;

  if (change.type === "added" || change.type === "modified") {
    const eventData = {
      id: eventId,
      title: docData.subject || "No Title",
      start: docData.publishDate,
      extendedProps: {
        platform: docData.postFB ? "Facebook" : docData.postInst ? "Instagram" : null,
        time: formatPublishTime(docData.publishTime),
        icon: docData.postFB ? "facebook" : docData.postInst ? "instagram" : null,
        addresses: docData.children ? docData.children.map(child => child.adressen) : [],
        mediaFiles: docData.children?.[0]?.images || [],
        children: docData.children || [],
        status: collectionName === "postSend" ? "Opslået" : "Planlagt",
        text: docData.text || "No description available",
        collection: collectionName,
        data: docData
      },
      className: docData.postFB ? "bg-gradient-facebook" : docData.postInst ? "bg-gradient-instagram" : null,
    };

    const existingEvent = calendar.getEventById(eventId);
    if (existingEvent) {
      existingEvent.remove();
    }
    calendar.addEvent(eventData);
  } else if (change.type === "removed") {
    const existingEvent = calendar.getEventById(eventId);
    if (existingEvent) {
      existingEvent.remove();
    }
  }
};

const formatPublishTime = (publishTime) => {
  if (!publishTime) return "00:00";
  
  if (typeof publishTime === "object" && publishTime.HH && publishTime.MM) {
    return `${publishTime.HH.toString().padStart(2, "0")}:${publishTime.MM.toString().padStart(2, "0")}`;
  } else if (typeof publishTime === "string") {
    return publishTime;
  } else if (publishTime instanceof Date) {
    return `${publishTime.getHours().toString().padStart(2, "0")}:${publishTime.getMinutes().toString().padStart(2, "0")}`;
  } else if (typeof publishTime === "number") {
    const date = new Date(publishTime);
    return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  }
  
  return "00:00";
};

// Watch for route changes to update analytics
watch(
  () => route.params.id,
  () => {
    countPostsAndMedia();
  }
);

// Eksportér analytics hvis du skal bruge det i template
defineExpose({
  analytics
});

// Tilføj computed properties efter analytics ref
const totalPosts = computed(() => analytics.value.totalPosts)
const imageCount = computed(() => analytics.value.totalImages)
const animationCount = computed(() => analytics.value.totalAnimations)
const totalClicks = computed(() => analytics.value.totalClicks)
const totalReach = computed(() => analytics.value.totalReach)

// Tilføj denne watch
watch(() => currentUser.value, (newUser, oldUser) => {
  if (!newUser && oldUser) {
    // Bruger er logget ud
    if (calendar) {
      calendar.destroy();
    }
    // Fjern alle listeners
    if (typeof unsubscribe === 'function') {
      unsubscribe();
    }
    if (typeof unsubscribeSend === 'function') {
      unsubscribeSend();
    }
  }
}, { immediate: true });

// Tilføj ref til GetLinks komponenten
const linksModalRef = ref(null)

// Opdater showLinksModal metoden
const showLinksModal = () => {
  linksModalRef.value?.openModal()
}

// Opdater updateAnalytics funktionen
const updateAnalytics = async () => {
  try {
    const userId = route.params.id || store.state.activeUser?.id;
    if (!userId) return;

    // Hent brugerens dubTagId
    const db = getFirestore();
    const userDoc = await getDoc(doc(db, "users", userId));
    const dubTagId = userDoc.data()?.dubTagId;

    if (!dubTagId) {
      console.log('Ingen dubTagId fundet for brugeren');
      return;
    }

    // Lav direkte HTTP kald til cloud function
    const response = await axios.get(`https://us-central1-toft-d4f39.cloudfunctions.net/getDubAnalytics`, {
      params: { userId }
    });

    console.log('Analytics opdateret:', response.data);
    return response.data;
  } catch (error) {
    console.error('Fejl ved opdatering af analytics:', error);
  }
};

// Tilføj ref til SocialMediaAnalytics komponenten
const analyticsModalRef = ref(null);
</script>
<template>
  <div class="calendar-container">
    <div class="row mb-4">
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-dark shadow">
                <i class="ni ni-calendar-grid-58 text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Antal opslag i alt</p>
                  <h5 class="mb-0 font-weight-bolder">{{ totalPosts }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-primary shadow">
                <i class="ni ni-image text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Billeder lavet</p>
                  <h5 class="mb-0 font-weight-bolder">{{ imageCount }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-success shadow">
                <i class="ni ni-camera-compact text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Videoer lavet</p>
                  <h5 class="mb-0 font-weight-bolder">{{ animationCount }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3 clickable-card" @click="showLinksModal">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-info shadow">
                <i class="ni ni-curved-next text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Totale kliks modtaget</p>
                  <h5 class="mb-0 font-weight-bolder">{{ totalClicks }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-warning shadow">
                <i class="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Total Rækkevidde</p>
                  <h5 class="mb-0 font-weight-bolder">{{ totalReach.toLocaleString('da-DK') }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-2 col-sm-6 mb-xl-0 mb-4">
        <div class="card mb-3 clickable-card" @click="showAnalytics">
          <div class="card-body p-3">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div class="text-center icon icon-shape bg-gradient-info shadow">
                <i class="fas fa-chart-line text-lg opacity-10" aria-hidden="true"></i>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">Se udvidet aktivitet</p>
                  <small class="text-xs text-muted">Data opdateres hver 24. time</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading indikator -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-content">
        <div class="spinner"></div>
        <div class="progress-bar">
          <div :style="{ width: loadingProgress + '%' }" class="progress"></div>
        </div>
        <div class="loading-text">Indlæser kalender... {{ loadingProgress }}%</div>
      </div>
    </div>

    <!-- Kalender -->
    <div ref="calendarEl" :class="{ 'loading': isLoading }"></div>

    <!-- GetLinks komponent -->
    <GetLinks ref="linksModalRef" />
    <SocialMediaAnalytics ref="analyticsModalRef" />
  </div>
</template>
<style scoped>
.fc-daygrid-event-harness {
  margin-top: 0 !important;
}

/* Opdateret styling for calendar container */
.calendar-container {
  background: rgba(255, 255, 255, 0.05);/* Mere solid baggrund */
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  position: relative; /* Tilføjet for at sikre korrekt z-index stacking */
  z-index: 1; /* Basis z-index */
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
}

/* Card styling */
.card {
  background: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(10px);
  border-radius: 20px !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  box-shadow: 
    0 8px 32px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  transform: translateZ(0);
  will-change: transform;
  transition: all 0.3s cubic-bezier(0.2, 0, 0.2, 1);
}

.fc-event-main {
  padding: 5px;
  position: relative;
}

.fc-title {
  font-weight: bold;
  margin-bottom: 5px;
  padding-right: 20px;
}

.children-container {
  font-size: 0.9em;
  padding: 0;
  margin-bottom: 0;
}

.child-element {
  list-style-type: none;
  margin-left: 0;
  text-align: left;
}

.fc-post-text-row {
  font-size: 0.8em;
  margin-top: 5px;
}

.fc-post-text {
  font-weight: bold;
}

.fc-datetime-text {
  color: #c5c5c5;
  font-weight: bold;
}

.show-more-link,
.show-less-link {
  font-size: 0.8em;
  color: blue;
  text-decoration: none;
  cursor: pointer;
}

/* Tilføj disse klasser til din komponent eller App.vue for at påvirke FullCalendar's standardstil */
:deep(.fc-daygrid-event) {
  white-space: normal !important;
  --fc-event-text-color: #ffffff !important;
}

:deep(.bg-class-posted) {
  background-color: #4CAF50 !important;
}

:deep(.bg-class-instagram) {
  background-color: #C13584 !important;
}

:deep(.bg-class-facebook) {
  background-color: #3b5998 !important;
}

:deep(.fc-daygrid-day-events) {
  min-height: auto !important;
}

:deep(.fc-daygrid-day-bottom) {
  margin-top: 0 !important;
}

:deep(.fc-h-event) {
  border: none !important;
}

:deep(.fc-event-main) {
  padding: 3px !important;
}

:deep(.fc-event-title) {
  padding: 0 !important;
}

:deep(.event-inner-container) {
  background-color: inherit;
  height: 100%;
}

:deep(.fc-daygrid-event) {
  white-space: normal !important;
  overflow: hidden;
}

.child-element div {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

:deep(.fc-event) {
  background: linear-gradient(310deg, #1171ef 0%, #4c619e 100%) !important;
}

/* Style for dagens dato */
:deep(.fc-day-today) {
  background: linear-gradient(310deg, rgba(65, 65, 65, 0.501) 0%, rgba(65, 65, 65, 0.1) 100%) !important;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  text-align: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

.progress-bar {
  width: 200px;
  height: 4px;
  background: #f3f3f3;
  border-radius: 2px;
  margin: 10px auto;
}

.progress {
  height: 100%;
  background: linear-gradient(310deg, #11cdef 0%, #1171ef 100%);
  border-radius: 2px;
  transition: width 0.3s ease;
}

.loading-text {
  color: #666;
  font-size: 14px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

:deep(.fc-daygrid-more-link) {
  color: #5e72e4 !important;
  background: transparent !important;
  padding: 2px !important;
  margin-top: 2px !important;
  font-weight: bold !important;
}

:deep(.fc-daygrid-day-bottom) {
  padding: 2px !important;
}

:deep(.fc-view-harness) {
  position: relative;
  z-index: 1;
  min-height: 600px; /* Sikrer minimum højde */
}

:deep(.fc-popover-header) {
  background: #f8f9fa !important;
  padding: 8px !important;
  border-bottom: 1px solid #dee2e6 !important;
}

/* Styling for "vis mere" link */
:deep(.fc-more-link) {
  background: transparent !important;
  color: #5e72e4 !important;
  font-weight: bold !important;
  padding: 2px 5px !important;
  margin: 2px 0 !important;
  cursor: pointer !important;
  transition: all 0.2s ease !important;
}

:deep(.fc-more-link:hover) {
  color: #233dd2 !important;
  text-decoration: underline !important;
}

/* Styling for popover der viser alle events */
:deep(.fc-popover) {
  background: white !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06) !important;
  border: 1px solid rgba(0,0,0,0.1) !important;
}

:deep(.fc-popover-header) {
  background: #f8f9fa !important;
  padding: 8px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom: 1px solid rgba(0,0,0,0.1) !important;
}

:deep(.fc-popover-body) {
  padding: 8px !important;
  max-height: 300px !important;
  overflow-y: auto !important;
}

/* Tilpas scrollbar i popover */
:deep(.fc-popover-body::-webkit-scrollbar) {
  width: 6px !important;
}

:deep(.fc-popover-body::-webkit-scrollbar-track) {
  background: #f1f1f1 !important;
}

:deep(.fc-popover-body::-webkit-scrollbar-thumb) {
  background: #888 !important;
  border-radius: 3px !important;
}

:deep(.fc-popover-body::-webkit-scrollbar-thumb:hover) {
  background: #555 !important;
}

:deep(.fc-daygrid-event-harness) {
  margin-top: 0 !important;
  position: relative !important;
  z-index: 2 !important;
}

/* Tilføj disse for at sikre korrekt drag-and-drop */
:deep(.fc-event) {
  cursor: grab !important;
  z-index: 3 !important;
}

:deep(.fc-event.fc-dragging) {
  z-index: 1000 !important;
  cursor: grabbing !important;
}

.clickable-card {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.clickable-card::after {
  content: "Klik for at se detaljer";
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  background: rgba(46, 46, 46, 0.9);
  color: white;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  transition: bottom 0.3s ease;
}

.clickable-card:hover::after {
  bottom: 0;
}

.clickable-card:hover {
  transform: translateY(-3px);
  box-shadow: 
    0 12px 40px 0 rgba(31, 38, 135, 0.37),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

/* Tilføj pulserende effekt på ikonet */
.clickable-card:hover .icon {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>