<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";
import { useRouter } from "vue-router";

// Argon components
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const ROLE_ADMIN = 1;
const ROLE_USER = 3;
const ROLE_MANAGER = 2;

const email = ref("");
const password = ref("");
const rememberMe = ref(false);
const errorMessage = ref("");
const isLoading = ref(false);
const router = useRouter();
const store = useStore();

const signIn = async () => {
  isLoading.value = true;
  try {
    const persistenceType = rememberMe.value
      ? browserLocalPersistence
      : browserSessionPersistence;
    await setPersistence(auth, persistenceType);

    const userCredential = await signInWithEmailAndPassword(
      auth,
      email.value,
      password.value,
    );
    const user = userCredential.user;

    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      sessionStorage.removeItem('lastActivity');
      await updateDoc(userDocRef, { isOnline: true });
      store.dispatch("setCurrentUser", { ...userData, id: user.uid, isOnline: true });

      if (userData.role === ROLE_ADMIN || userData.role === ROLE_MANAGER) {
        router.push("/pages/tables");
      } else if (userData.role === ROLE_USER) {
        router.push("/profile");
      } else {
        router.push("/default-route");
      }
    } else {
      errorMessage.value = "User data not found. Please contact support.";
    }
  } catch (error) {
    errorMessage.value = error.message;
  } finally {
    isLoading.value = false;
  }
};
</script>

<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain bg-black text-white">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder text-pink-300">Sign In</h4>
                  <p class="mb-0 text-black-400">
                    Enter your email and password to sign in
                  </p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="signIn">
                    <div class="mb-3">
                      <argon-input
                        id="email"
                        v-model="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                        aria-label="Email"
                        class="text-white placeholder-gray-500 bg-black border-gray-600 focus:ring-pink-500"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        v-model="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                        aria-label="Password"
                        class="text-white placeholder-gray-500 bg-black border-gray-600 focus:ring-pink-500"
                      />
                    </div>
                    <argon-switch
                      id="rememberMe"
                      v-model="rememberMe"
                      name="remember-me"
                      class="text-white"
                    >
                      Remember me
                    </argon-switch>

                    <div v-if="errorMessage" class="text-red-500 text-sm">
                      {{ errorMessage }}
                    </div>

                    <div class="text-center">
                      <argon-button
                        :disabled="isLoading"
                        type="submit"
                        class="mt-4 bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 rounded-full hover:opacity-90 focus:ring-pink-500"
                        variant="gradient"
                        color="success"
                        full-width
                        size="lg"
                      >
                        <span v-if="!isLoading">Sign in</span>
                        <span v-else>Signing in...</span>
                      </argon-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
.card-header {
  padding: 1.5rem 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: none;
}

.error-message {
  color: #ff4444;
  margin-top: 10px;
  text-align: center;
}
</style>
