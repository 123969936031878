<template>
  <main>
    <div class="container-fluid">
      <!-- Header and navigation pills -->
      <div
        class="page-header min-height-250"
        :style="{
          marginRight: '-24px',
          marginLeft: '-5%'
        }"
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <img
                  :src="userData.userSettings?.profileImage || '../assets/img/default-profile.png'"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                />
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{ userData.name }}</h5>
              </div>
            </div>
            <div class="col d-flex align-items-center justify-content-end">
              <argon-button
                color="primary"
                size="sm"
                v-if="activeUser && (activeUser.role === 1 || activeUser.role === 2)"
                variant="outline"
                @click="gobackToTable"
                class="me-3"
              >
                Gå tilbage
              </argon-button>
              <div class="nav-wrapper position-relative end-0">
                <!-- Navigation pills -->
                <ul class="nav nav-pills nav-fill p-1" role="tablist">
                  <li class="nav-item" v-for="tab in visibleTabs" :key="tab.value">
                    <template v-if="tab.value === 'newProject'">
                      <div class="dropdown">
                        <a
                          class="nav-link custom-nav-link dropdown-toggle"
                          :class="{ 'active-tab': activeTab === 'newProject' }"
                          href="#"
                          role="button"
                          @click.prevent="toggleDropdown"
                        >
                          {{ tab.label }}
                        </a>
                        <ul class="dropdown-menu" :class="{ 'show': isDropdownOpen }">
                          <li v-for="subTab in newProjectSubTabs" :key="subTab.value">
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="selectNewProjectSubTab(subTab.value)"
                            >
                              {{ subTab.label }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </template>
                    <a
                      v-else
                      class="nav-link custom-nav-link"
                      :class="{ 'active-tab': activeTab === tab.value }"
                      href="#"
                      role="tab"
                      @click.prevent="$event => changeTab(tab.value)"
                    >
                      {{ tab.label }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-4 container-fluid">
      <div class="row">
        <!-- Venstre kolonne med navigation -->
        <div class="col-md-3" v-if="activeTab === 'profile'">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item" v-for="item in menuItems" :key="item.id">
              <a 
                class="nav-link text-body d-flex align-items-center" 
                :class="{ 'active': activeSection === item.id }"
                href="#" 
                @click.prevent="activeSection = item.id"
              >
                <i :class="item.icon + ' me-2 text-dark opacity-6'"></i>
                <span class="text-sm">{{ item.label }}</span>
              </a>
            </li>
          </ul>
        </div>
        
        <!-- Midterste kolonne med indhold -->
        <div :class="{'col-md-6': activeTab === 'profile', 'col-md-12': activeTab !== 'profile'}">
          <!-- Tab Content -->
          <div v-if="activeTab === 'profile'">
            <!-- Profile Edit Card -->
            <div class="card">
              <div class="card-body p-3">
                <!-- Indholdssektion -->
                <div class="tab-content">
                  <!-- Brugerinformation -->
                  <div v-if="activeSection === 'basicInfo'">
                    <h6 class="mb-3">Brugerinformation</h6>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">Firmanavn</label>
                          <argon-input v-model="userData.name" type="text" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">Email</label>
                          <argon-input v-model="userData.email" type="email" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">CVR</label>
                          <argon-input v-model="userData.cvr" type="text" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">Telefon</label>
                          <argon-input v-model="userData.phone" type="text" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label">Adresse</label>
                          <argon-input v-model="userData.address" type="text" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Hjemmesider -->
                  <div v-if="activeSection === 'websites'">
                    <h6 class="mb-3">Linkoplysninger</h6>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">Hovedside</label>
                          <argon-input v-model="userData.userLinks.mainWebsite" type="url" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">Booking</label>
                          <argon-input v-model="userData.userLinks.bookingWebsite" type="url" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">Webshop</label>
                          <argon-input v-model="userData.userLinks.webshopWebsite" type="url" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-control-label">Website</label>
                          <argon-input v-model="userData.userLinks.website" type="url" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Kontaktoplysninger -->
                  <div v-if="activeSection === 'contactInfo'">
                    <h6 class="mb-3">Kontaktoplysninger</h6>
                    <!-- Tilføj kontaktoplysninger formular her -->
                  </div>

                  <!-- Faktureringsoplysninger -->
                  <div v-if="activeSection === 'billingInfo'">
                    <h6 class="mb-3">Faktureringsoplysninger</h6>
                    <!-- Tilføj faktureringsoplysninger formular her -->
                  </div>

                  <!-- Rediger profil sektion -->
                  <div v-if="activeSection === 'editProfile'">
                    <h6 class="mb-3">Profilbillede</h6>
                    <p class="text-sm mb-3">
                      Opdater dit profilbillede her.
                    </p>
                    <div class="row mb-4">
                      <div class="col-12 d-flex align-items-center">
                        <div class="me-4">
                          <div class="image-upload-container profile-container mb-2">
                            <input type="file" id="profileImageUpload" @change="handleProfileImageUpload" accept="image/*" class="image-upload-input">
                            <label for="profileImageUpload" class="image-upload-label">
                              <img v-if="profileImagePreview" :src="profileImagePreview" alt="Profilbillede" class="preview-image">
                              <img v-else-if="userData.userSettings?.profileImage" :src="userData.userSettings.profileImage" alt="Profilbillede" class="preview-image">
                              <div v-else class="upload-icon-wrapper">
                                <i class="fas fa-image"></i>
                              </div>
                            </label>
                          </div>
                          <label class="form-label text-center d-block small">Profilbillede</label>
                        </div>
                      </div>
                    </div>
                    
                    <!-- Facebook import knap -->
                    <div class="d-flex justify-content-between align-items-center mb-4">
                      <argon-button @click="fetchAndUploadImages" color="dark" size="sm" class="d-flex align-items-center">
                        <i class="fab fa-facebook me-2"></i>
                        Hent fra Facebook
                      </argon-button>
                    </div>
                  </div>

                  <!-- Skift adgangskode og email sektion -->
                  <div v-if="activeSection === 'changePassword'">
                    <h6 class="mb-3">Nulstil adgangskode</h6>
                    <p class="text-sm mb-3">
                      Nulstil din adgangskode her på denne mail: {{ auth.currentUser?.email }}.
                    </p>
                    <div class="form-group">
                      <argon-input 
                        v-model="resetEmail" 
                        id="reset-email" 
                        type="email" 
                        placeholder="Din e-mailadresse" 
                        label="E-mail"
                      />
                    </div>
                    <argon-button @click="resetPassword" color="info" fullWidth>Send nulstillingslink</argon-button>
                    
                    <h6 class="mb-3 mt-4">Ændre login-email</h6>
                    <p class="text-sm mb-3">
                      Din nuværende email er: {{ auth.currentUser?.email }}
                    </p>
                    <div class="form-group">
                      <argon-input 
                        v-model="newEmail" 
                        id="new-email" 
                        type="email" 
                        placeholder="Indtast en ny e-mailadresse" 
                        label="Ny E-mail"
                      />
                    </div>
                    <argon-button @click="updateEmail" color="primary" fullWidth>Opdater email</argon-button>

                    <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
                    <p v-if="successMessage" class="text-success mt-3">{{ successMessage }}</p>
                  </div>

                  <!-- Design Indstillinger -->
                  <div v-if="activeSection === 'designSettings'" class="design-settings">
                    <h6 class="mb-4">Design Indstillinger</h6>
                    <div class="settings-grid">
                      <!-- Tema Farve -->
                      <div class="setting-item">
                        <label class="setting-label">Tema Farve</label>
                        <div class="color-picker-wrapper">
                          <input type="color" v-model="themeColor" class="color-picker" />
                          <span class="color-value">{{ themeColor }}</span>
                        </div>
                      </div>
                      
                      <!-- Tekst Farve -->
                      <div class="setting-item">
                        <label class="setting-label">Tekst Farve</label>
                        <div class="color-picker-wrapper">
                          <input type="color" v-model="textColor" class="color-picker" />
                          <span class="color-value">{{ textColor }}</span>
                        </div>
                      </div>
                      
                      <!-- Logo -->
                      <div class="setting-item">
                        <label class="setting-label">Logo</label>
                        <div class="logo-upload-container">
                          <input type="file" id="logoUpload" @change="handleLogoUpload" accept="image/*" class="logo-upload-input">
                          <label for="logoUpload" class="logo-upload-label">
                            <img v-if="logoPreview" :src="logoPreview" alt="Logo" class="logo-preview">
                            <img v-else-if="userData.userSettings?.logo" :src="userData.userSettings.logo" alt="Logo" class="logo-preview">
                            <div v-else class="upload-placeholder">
                              <i class="fas fa-cloud-upload-alt"></i>
                              <span>Upload logo</span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    
                    <!-- HashTags -->
                    <div class="setting-item mt-4">
                      <label class="setting-label">HashTags (max 17)</label>
                      <div class="hashtag-input-wrapper">
                        <input 
                          type="text" 
                          v-model="newHashTag" 
                          @keyup.enter="addHashTag" 
                          placeholder="Tilføj hashtag og tryk enter" 
                          class="form-control"
                        >
                      </div>
                      <div class="hashtag-list">
                        <span v-for="(tag, index) in hashTags" :key="index" class="hashtag-item">
                          #{{ tag }}
                          <button @click="removeHashTag(index)" class="remove-hashtag">&times;</button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Handlinger -->
                <div v-if="activeSection !== 'changePassword'" class="d-flex justify-content-between align-items-center mt-4">
                  <argon-button 
                    @click="updateUserData" 
                    color="success"
                    size="sm"
                    variant="gradient"
                  >
                    Gem ændringer
                  </argon-button>
                </div>
              </div>
            </div>
          </div>

          <!-- New Project Tab Content -->
          <div v-else-if="activeTab === 'newProject'" class="content-offset">
            <div v-if="activeNewProjectSubTab === 'manual'">
              <new-project />
            </div>
            <div v-else-if="activeNewProjectSubTab === 'car'">
              <car-post />
            </div>
            <div v-else-if="activeNewProjectSubTab === 'autopilot'">
              <auto-pilot />
            </div>
            <div v-else-if="activeNewProjectSubTab === 'autopostManager'">
              <auto-post-manager @create-new="() => {
                activeNewProjectSubTab = 'autopilot';
              }" />
            </div>
          </div>

          <!-- New Tab Content for Dealer Cars -->
          <div v-else-if="activeTab === 'dealerCars'">
            <get-dealer-cars />
          </div>

          <!-- Calendar Tab Content -->
          <div v-else-if="activeTab === 'calendar'">
            <Calendar />
          </div>

          <!-- Print Materials Tab Content -->
          <div v-else-if="activeTab === 'printMaterials'" class="content-offset">
            <print-materials />
          </div>

          <!-- Autopost Manager Tab Content -->
          <div v-else-if="activeTab === 'autopostManager'" class="content-offset">
            <auto-post-manager />
          </div>

          <!-- Live Editor Tab Content -->
          <div v-else-if="activeTab === 'liveEditor'" class="content-offset">
            <live-editor 
              template-id="6718bfc6bc4b170e562be47f"
            />
          </div>
        </div>

        <!-- Højre kolonne med Profile Card -->
        <div class="col-md-3" v-if="activeTab === 'profile'">
          <profile-card :user-data="userData" />
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeMount, onBeforeUnmount, watch } from "vue";
import { useStore } from "vuex";
import ProfileCard from "./components/ProfileCard.vue";
import NewProject from "./components/NewProject.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { doc, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { db, auth } from "../../firebase.js";
import { uploadFile } from "../../services/uploadeService.js";
import Calendar from "./Calendar.vue";
import { useRouter, useRoute } from "vue-router";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { usePasswordManagement } from './components/usePasswordManagement';
import { updateEmail as firebaseUpdateEmail, sendEmailVerification, sendSignInLinkToEmail } from "firebase/auth";
import GetDealerCars from "./GetDealerCars.vue";
import CarPost from "./components/postCars.vue";
import AutoPilot from "./components/AutoPilot.vue";
import PrintMaterials from "./components/PrintMaterials.vue";
import LiveEditor from "./components/liveEditor.vue";
import AutoPostManager from './components/AutoPostManager.vue';
import { signOut } from "firebase/auth";
import axios from 'axios';

const store = useStore();
const router = useRouter();
const route = useRoute();
const activeUser = computed(() => store.getters.activeUser);

// Active tab state
const activeTab = ref('profile');

const tabs = [
  { value: 'printMaterials', label: 'Printmaterialer' },
  { value: 'autopostManager', label: 'Auto Pilot' },
  { value: 'newProject', label: 'Opret opslag' },
  { value: 'calendar', label: 'Kalender' },
  { value: 'dealerCars', label: 'Mine biler' },
  { value: 'profile', label: 'Profil' },
  { value: 'logout', label: 'Log ud', icon: 'fas fa-right-from-bracket' }
];

const visibleTabs = computed(() => {
  if (userData.value.companyType === 'auto') {
    return tabs;
  } else {
    return tabs.filter(tab => tab.value !== 'dealerCars');
  }
});

const userData = ref({
  address: "",
  companyId: "",
  companyType: "",
  createdBy: "",
  cvr: 0,
  email: "",
  id: "",
  isOnline: false,
  name: "",
  phone: "",
  role: 0,
  userSettings: {
    headerImage: "",
    profileImage: ""
  },
  userLinks: {
    bookingWebsite: "",
    mainWebsite: "",
    webshopWebsite: "",
    website: ""
  },
  MetaSettings: {},
  settings: {}
});

const activeSection = ref('basicInfo');

const menuItems = computed(() => {
  const items = [
    { id: 'basicInfo', label: 'Information', icon: 'fas fa-user' },
    { id: 'websites', label: 'Linkoplysninger', icon: 'fas fa-globe' },
    { id: 'editProfile', label: 'Profil & Cover billede', icon: 'fas fa-edit' },
    { id: 'changePassword', label: 'Nulstil adgangskode', icon: 'fas fa-key' },
  ];
  
  if (userData.value.companyType === 'auto') {
    items.splice(3, 0, { id: 'designSettings', label: 'Design Indstillinger', icon: 'fas fa-paint-brush' });
  }

  return items;
});

const profileImageFile = ref(null);
const headerImageFile = ref(null);
const profileImagePreview = ref(null);
const headerImagePreview = ref(null);

const {
  resetEmail,
  errorMessage,
  successMessage,
  resetPassword,
  loadUserEmail
} = usePasswordManagement();

const newEmail = ref("");

const logoFile = ref(null);
const logoPreview = ref(null);
const themeColor = ref(userData.value.userSettings.themeColor || '#ffffff');
const textColor = ref(userData.value.userSettings.textColor || '#000000');

const activeNewProjectSubTab = ref("manual");
const isDropdownOpen = ref(false);

const newProjectSubTabs = [
  { value: 'car', label: 'Bil opslag' },
  { value: 'manual', label: 'Standard opslag' }
];

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const selectNewProjectSubTab = (value) => {
  activeNewProjectSubTab.value = value;
  activeTab.value = 'newProject';
  isDropdownOpen.value = false;
};

const updateEmail = async () => {
  errorMessage.value = '';
  successMessage.value = '';

  if (!newEmail.value) {
    errorMessage.value = "Indtast venligst en ny e-mailadresse.";
    return;
  }

  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Ingen bruger er logget ind');
    }

    await firebaseUpdateEmail(user, newEmail.value);
    await sendEmailVerification(user);
    
    successMessage.value = "En verifikationsmail er sendt til din nye e-mailadresse. Tjek venligst din indbakke og følg instruktionerne.";
    newEmail.value = "";
  } catch (error) {
    console.error("Fejl ved opdatering af email:", error);
    
    switch (error.code) {
      case 'auth/requires-recent-login':
        errorMessage.value = "For din sikkerhed skal du logge ind igen før du kan ændre din email.";
        break;
      case 'auth/invalid-email':
        errorMessage.value = "Ugyldig e-mailadresse format.";
        break;
      case 'auth/email-already-in-use':
        errorMessage.value = "Denne e-mailadresse er allerede i brug.";
        break;
      default:
        errorMessage.value = `Der opstod en fejl: ${error.message}`;
    }
  }
};

const generateManualLink = () => {
  try {
    const actionCodeSettings = {
      url: 'https://toft-d4f39.firebaseapp.com/finalize-email-update',
      handleCodeInApp: true,
    };

    // Generer en unik identifikator
    const uniqueId = Math.random().toString(36).substring(2, 15);

    // Konstruer linket manuelt
    const baseUrl = 'https://toft-d4f39.firebaseapp.com/__/auth/action';
    const params = new URLSearchParams({
      mode: 'signIn',
      oobCode: uniqueId,
      apiKey: auth.app.options.apiKey,
      continueUrl: actionCodeSettings.url,
    });

    const manualLink = `${baseUrl}?${params.toString()}`;
    console.log("Manuelt genereret email-link:", manualLink);
    
    successMessage.value = "Et manuelt link er blevet genereret. Tjek konsollen for detaljer.";
    return manualLink;
  } catch (error) {
    console.error("Fejl ved generering af manuelt link:", error);
    errorMessage.value = `Fejl ved generering af manuelt link: ${error.message}`;
    return null;
  }
};

const fetchUserData = async (id) => {
  if (id) {
    try {
      const userDocRef = doc(db, "users", id);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const data = userDoc.data();
        const { userLinks, userSettings, ...restData } = data;
        
        // Fjern linkoplysninger fra rodniveauet
        delete restData.bookingWebsite;
        delete restData.mainWebsite;
        delete restData.webshopWebsite;
        delete restData.website;

        userData.value = { 
          ...restData, 
          id,
          userSettings: {
            ...userSettings,
            themeColor: userSettings?.themeColor || '#ffffff',
            textColor: userSettings?.textColor || '#000000',
            logo: userSettings?.logo || ''
          },
          userLinks: userLinks || {
            bookingWebsite: "",
            mainWebsite: "",
            webshopWebsite: "",
            website: ""
          }
        };
        console.log("Fetched user data:", userData.value);
      } else {
        console.log("Ingen brugerdata fundet!");
      }
    } catch (error) {
      console.error("Fejl ved hentning af brugerdata:", error);
    }
  } else {
    console.log("Bruger-ID mangler.");
  }
};

const handleProfileImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.size > 5 * 1024 * 1024) { // 5 MB grænse
      alert("Filen er for stor. Maksimal størrelse er 5 MB.");
      return;
    }
    if (!file.type.startsWith('image/')) {
      alert("Kun billedfiler er tilladt.");
      return;
    }
    profileImageFile.value = file;
    profileImagePreview.value = URL.createObjectURL(file);
  }
};

const handleHeaderImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.size > 10 * 1024 * 1024) { // 10 MB grænse
      alert("Filen er for stor. Maksimal størrelse er 10 MB.");
      return;
    }
    if (!file.type.startsWith('image/')) {
      alert("Kun billedfiler er tilladt.");
      return;
    }
    headerImageFile.value = file;
    headerImagePreview.value = URL.createObjectURL(file);
  }
};

const handleLogoUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (file.size > 5 * 1024 * 1024) { // 5 MB grænse
      alert("Filen er for stor. Maksimal størrelse er 5 MB.");
      return;
    }
    if (!file.type.startsWith('image/')) {
      alert("Kun billedfiler er tilladt.");
      return;
    }
    logoFile.value = file;
    logoPreview.value = URL.createObjectURL(file);
  }
};

const onFileChange = async (event) => {
  const file = event.target.files[0];
  if (file && userData.value && userData.value.id) {
    try {
      await uploadProfileImage(file, userData.value.id);
    } catch (error) {
      console.error("Fejl ved upload af profilbillede:", error);
      alert(`Der opstod en fejl ved upload af profilbillede: ${error.message}`);
    }
  } else {
    console.log("Ingen fil valgt eller bruger er ikke logget ind.");
  }
};

const uploadProfileImage = async (file, userId) => {
  try {
    const downloadURL = await uploadFile(file, "profileImages");
    if (downloadURL) {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, { 
        userSettings: {
          ...userData.value.userSettings,
          profileImage: downloadURL
        }
      });
      userData.value.userSettings.profileImage = downloadURL;
      alert("Profilbillede opdateret!");
    } else {
      throw new Error("Download URL er undefined.");
    }
  } catch (error) {
    console.error("Fejl ved upload af profilbillede:", error);
    alert(`Der opstod en fejl ved upload af profilbillede: ${error.message}`);
  }
};

const updateUserData = async () => {
  const user = auth.currentUser;
  if (!user) {
    console.error("Ingen bruger er logget ind");
    alert("Du skal være logget ind for at opdatere din profil");
    return;
  }

  console.log("Logged in user ID:", user.uid);
  console.log("userData ID:", userData.value.id);

  if (userData.value && userData.value.id) {
    try {
      const userDocRef = doc(db, "users", userData.value.id);
      const { userLinks, userSettings, ...restUserData } = userData.value;
      
      // Fjern linkoplysninger fra rodniveauet
      delete restUserData.bookingWebsite;
      delete restUserData.mainWebsite;
      delete restUserData.webshopWebsite;
      delete restUserData.website;

      const updateData = {
        ...restUserData,
        userSettings: {
          ...userSettings,
          hashTags: hashTags.value
        },
        userLinks: {
          ...userLinks
        }
      };

      const storage = getStorage();

      if (profileImageFile.value) {
        console.log("Forsøger at uploade profilbillede");
        const imageRef = storageRef(storage, `profileImages/${userData.value.id}/${profileImageFile.value.name}`);
        await uploadBytes(imageRef, profileImageFile.value);
        console.log("Profilbillede uploadet");
        const downloadURL = await getDownloadURL(imageRef);
        console.log("Profilbillede download URL:", downloadURL);
        updateData.userSettings.profileImage = downloadURL;
      }

      if (headerImageFile.value) {
        console.log("Forsøger at uploade headerbillede");
        const imageRef = storageRef(storage, `headerImages/${userData.value.id}/${headerImageFile.value.name}`);
        await uploadBytes(imageRef, headerImageFile.value);
        console.log("Headerbillede uploadet");
        const downloadURL = await getDownloadURL(imageRef);
        console.log("Headerbillede download URL:", downloadURL);
        updateData.userSettings.headerImage = downloadURL;
      }

      if (userData.value.companyType === 'auto') {
        updateData.userSettings.themeColor = themeColor.value;
        updateData.userSettings.textColor = textColor.value;

        if (logoFile.value) {
          console.log("Forsøger at uploade logo");
          const imageRef = storageRef(storage, `logos/${userData.value.id}/${logoFile.value.name}`);
          await uploadBytes(imageRef, logoFile.value);
          console.log("Logo uploadet");
          const downloadURL = await getDownloadURL(imageRef);
          console.log("Logo download URL:", downloadURL);
          updateData.userSettings.logo = downloadURL;
        }
      }

      await updateDoc(userDocRef, updateData);
      
      // Fjern linkoplysninger fra rodniveauet i Firebase
      await updateDoc(userDocRef, {
        bookingWebsite: deleteField(),
        mainWebsite: deleteField(),
        webshopWebsite: deleteField(),
        website: deleteField()
      });

      alert("Profil opdateret!");
    } catch (error) {
      console.error("Detaljeret fejl ved opdatering af profil: ", error);
      if (error.code === "storage/unauthorized") {
        console.error("Unauthorized error details:", error);
        alert("Du har ikke tilladelse til at uploade billeder. Kontakt venligst support. Fejlkode: " + error.code);
      } else {
        alert(`Fejl ved opdatering af profil: ${error.message}. Fejlkode: ${error.code}`);
      }
    }
  } else {
    console.log("Bruger-ID mangler.");
  }
};

const profileImageUrl = computed(() => {
  return profileImagePreview.value || userData.value.userSettings?.profileImage || '';
});

watch(profileImageUrl, (newValue) => {
  console.log("Profilbillede URL ændret:", newValue);
});

// Bevar bruger ID mellem refreshes
const userId = ref(route.params.id || store.state.activeUser?.id);

// Tilføj denne funktion til at håndtere Tawk.to integration
const initTawkTo = () => {
  if (userData.value.role === 3 && route.path.includes('/profile')) {
    try {
      // Fjern eksisterende Tawk.to script hvis det findes
      const existingScript = document.querySelector('script[src*="tawk.to"]');
      if (existingScript) {
        existingScript.remove();
      }

      // Definer visitor information før script indlæsning
      window.Tawk_API = window.Tawk_API || {};
      window.Tawk_API.visitor = {
        name: userData.value.name,
        email: userData.value.email
      };

      // Opret nyt script element
      const script = document.createElement("script");
      script.async = true;
      script.src = 'https://embed.tawk.to/67443f994304e3196ae82dde/1idh9gs93';
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');

      // Tilføj event listener for at sikre visitor data er sat
      script.onload = () => {
        if (window.Tawk_API) {
          window.Tawk_API.onLoad = function() {
            // Dobbeltsikrer at visitor data er sat
            window.Tawk_API.setAttributes({
              name: userData.value.name,
              email: userData.value.email,
              id: userData.value.id,
              companyId: userData.value.companyId
            }, function(error) {
              if (error) {
                console.error('Fejl ved indstilling af besøgerdata:', error);
              }
            });
          };
        }
      };

      const target = document.getElementsByTagName("script")[0] || 
                    document.getElementsByTagName("body")[0] || 
                    document.getElementsByTagName("head")[0];

      if (target) {
        target.parentNode.insertBefore(script, target);
      } else {
        document.body.appendChild(script);
      }
    } catch (error) {
      console.error('Fejl ved initialisering af Tawk.to:', error);
    }
  } else {
    removeTawkTo();
  }
};

// Separat funktion til at fjerne Tawk.to
const removeTawkTo = () => {
  try {
    const existingScript = document.querySelector('script[src*="tawk.to"]');
    if (existingScript) {
      existingScript.remove();
    }
    const customStyle = document.querySelector('style[data-tawk-style]');
    if (customStyle) {
      customStyle.remove();
    }
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
  } catch (error) {
    console.error('Fejl ved fjernelse af Tawk.to:', error);
  }
};

// Watch for route changes
watch(
  () => route.path,
  (newPath) => {
    if (newPath.includes('/profile')) {
      initTawkTo();
    } else {
      removeTawkTo();
    }
  }
);

// Opdater onMounted
onMounted(async () => {
  if (route.params.id) {
    await fetchUserData(route.params.id);
  } else {
    await fetchUserData(store.state.activeUser?.id);
  }
  
  // Kun initialiser hvis vi er på profile siden
  if (route.path.includes('/profile')) {
    initTawkTo();
  }
});

// Cleanup når komponenten unmounts
onBeforeUnmount(() => {
  removeTawkTo();
});

const gobackToTable = () => {
  router.push({ name: "Tables" });
}

const changeTab = (tabValue) => {
  if (tabValue === 'logout') {
    handleLogout();
    return;
  }
  activeTab.value = tabValue;
  console.log('Active tab changed to:', activeTab.value);
};

// Gør changeTab tilgængelig for template'en
defineExpose({ changeTab });

const hashTags = ref(userData.value.userSettings?.hashTags || []);
const newHashTag = ref('');

const addHashTag = () => {
  if (newHashTag.value && !hashTags.value.includes(newHashTag.value) && hashTags.value.length < 17) {
    hashTags.value.push(newHashTag.value);
    newHashTag.value = '';
  }
};

const removeHashTag = (index) => {
  hashTags.value.splice(index, 1);
};

const handleLogout = async () => {
  try {
    await signOut(auth);
    router.push('/login');
  } catch (error) {
    console.error('Fejl ved log ud:', error);
  }
};

const fetchAndUploadImages = async () => {
  const pageId = userData.value.MetaSettings.facebookPageId;
  const accessToken = userData.value.MetaSettings.page_access_token;

  if (!pageId || !accessToken) {
    alert("Facebook Page ID eller Access Token mangler.");
    return;
  }

  try {
    const profileImageUrl = await getProfilePictureUrl(pageId, accessToken);

    if (profileImageUrl) {
      userData.value.userSettings.profileImage = profileImageUrl;
    }

    await updateUserData(); // Opdater brugerdata i databasen
    alert("Profilbillede opdateret fra Facebook!");
  } catch (error) {
    console.error("Fejl ved hentning af billeder:", error);
    alert(`Der opstod en fejl ved hentning af billeder fra Facebook: ${error.message}`);
  }
};

const getProfilePictureUrl = async (pageId, accessToken) => {
  try {
    // Først henter vi billedets URL
    const url = `https://graph.facebook.com/v17.0/${pageId}?fields=picture.type(large)&access_token=${accessToken}`;
    const response = await axios.get(url);
    
    if (response.status === 200 && response.data.picture?.data?.url) {
      return response.data.picture.data.url;
    } else {
      console.error("Fejl ved hentning af profilbillede:", response);
      throw new Error("Kunne ikke hente profilbillede URL");
    }
  } catch (error) {
    console.error("Fejl i getProfilePictureUrl:", error);
    throw error;
  }
};
</script>

<style scoped>
.custom-nav-link {
  font-size: 0.8rem; /* Juster denne værdi efter behov */
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
}

.active-tab {
  background-color: #5e72e4; /* Primær farve, juster efter behov */
  color: rgb(91, 90, 90) !important;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.nav-pills .nav-link {
  border-radius: 0.5rem; /* Afrundede hjørner */
}

.nav-pills .nav-link:not(.active-tab):hover {
  background-color: #f6f9fc; /* Lysegrå baggrundsfarve ved hover */
}

.nav-wrapper {
  background: #f5f5f7;  /* Apple's signature light gray */
  border-radius: 8px;
  padding: 4px;
}

.nav-pills {
  display: flex;
  gap: 2px;
}

.nav-pills .nav-link {
  color: #1d1d1f;  /* Apple's dark text color */
  font-weight: 400;
  font-size: 13px;
  padding: 8px 16px;
  border-radius: 6px;
  transition: all 0.2s ease;
  letter-spacing: -0.01em;
  background: transparent;
}

.nav-pills .nav-link.active-tab {
  background: #ffffff;
  color: #1d1d1f;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.426);
}

.nav-pills .nav-link:not(.active-tab):hover {
  background: rgba(255, 255, 255, 0.5);
  color: #1d1d1f;
}

/* Dropdown styling */
.dropdown-menu {
  background: #f5f5f7;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 4px;
  padding: 4px;
}

.dropdown-item {
  color: #1d1d1f;
  font-size: 13px;
  padding: 8px 16px;
  border-radius: 6px;
}

.dropdown-item:hover {
  background: #ffffff;
  color: #1d1d1f;
}

.image-upload-container {
  position: relative;
  border: 2px solid #3a416f;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.profile-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  right: -10px;
}

.header-container {
  width: 200px;
  height: 60px;
}

.image-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.image-upload-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  left: -5px;
}

.image-upload-label img {
  object-position: left;
}

.image-upload-label i {
  margin-left: 0px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-image {
  object-position: center;
}

.upload-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-icon-wrapper i {
  font-size: 1.5rem;
  color: #3a416f;
}

.form-label.small {
  font-size: 0.75rem;
  margin-top: 0.25rem;
  text-align: center;
  width: 100%;
}

.page-header {
  background-size: cover;
  background-position: center;
}

.image-upload-container.logo-container {
  width: 100px;
  height: 100px;
}

.design-settings {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.setting-item {
  display: flex;
  flex-direction: column;
}

.setting-label {
  font-weight: 600;
  margin-bottom: 8px;
  color: #344767;
}

.color-picker-wrapper {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.color-picker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-picker::-webkit-color-swatch {
  border: none;
  border-radius: 4px;
}

.color-value {
  margin-left: 10px;
  font-size: 14px;
  color: #6c757d;
}

.logo-upload-container {
  width: 100%;
  height: 120px;
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.logo-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.logo-upload-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.logo-preview {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6c757d;
}

.upload-placeholder i {
  font-size: 24px;
  margin-bottom: 8px;
}

.nav-pills .nav-link {
  color: #344767;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  transition: all 0.15s ease;
}

.nav-pills .nav-link.active {
  color: #ffffff;
  background-color: #5e72e4;
}

.nav-pills .nav-link:not(.active):hover {
  color: #5e72e4;
  background-color: rgba(94, 114, 228, 0.1);
}

.dropdown-menu {
  display: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  color: #ffffff;
}

.dropdown-item:hover {
  background-color: #f6f9fc;
}

.nav-link.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.content-offset {
  margin-top: 45px; /* Flytter indholdet 25px ned */
}

.nav-link {
  color: #ffffff;
  transition: all 0.15s ease;
}

.nav-link:hover,
.nav-link.active {
  color: #5e72e4;
  background-color: rgba(94, 114, 228, 0.1);
}

.text-sm {
    font-size: 0.875rem !important;
    color: #ffffff;
}

.nav-link i {
  font-size: 1rem;
  color: #ffffff !important;
}

.border-radius-lg {
  border-radius: 0.5rem;
}

.hashtag-input-wrapper {
  margin-bottom: 10px;
}

.hashtag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.hashtag-item {
  background-color: #000000;
  padding: 2px 8px;
  border-radius: 15px;
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
}

.remove-hashtag {
  background: none;
  border: none;
  color: #6c757d;
  font-size: 1rem;
  cursor: pointer;
  padding: 0 0 0 5px;
}

.remove-hashtag:hover {
  color: #dc3545;
}

.bg-gradient-success {
    background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
}
</style>